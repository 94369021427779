export function ShowErrorAlert(errorType, type) {
  if (errorType === "SYSTEM_ERROR") alert("서버 오류입니다. 새로고침해주세요.");

  // Login
  if (errorType === "WRONG_IPADDRESS") alert("접근이 제한된 IP주소 입니다.");
  if (errorType === "INVALID_LOGIN_INFO")
    alert("아이디나 비밀번호가 잘못되었습니다.");
  if (errorType === "WRONG_OTP")
    alert("OPT를 잘못입력하셨습니다. 다시 입력해주세요.");

  // Lock 생성 수정 복구 시
  if (errorType === "COIN_NOT_ENOUGH") {
    if (type === "restore") alert("복구하려는 코인의 잔액이 부족합니다.");
    else if (type === "foundation") alert("재단 코인 잔액이 부족합니다.");
    else alert("잔액이 부족합니다.");
  }
  if (errorType === "WRONG_AMOUNT") alert("금액 수정이 불가합니다.");
  if (errorType === "PASSED_DATE")
    alert("현재시간 이후로 설정 가능합니다. 다시 선택해주세요.");

  // LOCK 멀티삭제 시
  if (errorType === "DELETED") alert("이미 LOCK입니다.");

  if (errorType === "DUPLICATE") {
    // 지갑 생성 시
    if (type === "id") alert("아이디가 중복되었습니다.");
    if (type === "name") alert("카테고리명이 중복되었습니다.");
    else alert("중복된 주소입니다.");
  }

  // P2P 제한 시갑 생성 시
  if (errorType === "INVALID_ADDRESS")
    alert("주소가 올바르지 않습니다. 다시 확인해주세요.");

  // 코인 배포 시
  if (errorType === "WRONG_COIN_TYPE")
    alert("코인 종류가 올바르지 않습니다. 다시 확인해주세요.");
  if (errorType === "ADDRESS_NOT_EXIST")
    alert("주소가 올바르지 않습니다. 다시 확인해주세요.");

  // 재단 코인 배포 시
  if (errorType === "FEE_NOT_ENOUGH") alert("플랫 잔액가 부족합니다.");

  // 외부지갑 코인 회수 시
  if (errorType === "INSUFFICIENT_BALANCE") {
    alert("보유수량보다 큰 수량을 입력하셨습니다. 다시 입력해주세요.");
  }

  // event
  if (errorType === "WRONG_DATE") alert("날짜를 확인해 주세요.");

  if (errorType === "SAME_PASSWORD")
    // mypage
    alert("이전 비밀번호와 같습니다. 다시 입력해주세요");

  // payment cancel
  if (errorType === "NOT_EXIST") alert("취소 불가합니다.");
  if (errorType === "ALREADY_CANCEL")
    alert("이미 취소중인거나 취소 처리된 건입니다.");
}
