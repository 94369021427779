import React, { Component } from "react";

import { Table, Button, Input } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import LinkDetailModal from "../../components/community/LinkDetailModal";
import { showAlert } from "../../components/common/AlertModal";

export default class Link extends Component {
  state = {
    linkList: [],
    pagination: {
      total: 0,
      current: 0
    },
    detailVisible: false,
    modalType: "",
    modalData: {},

    isModify: false,
    selectedRow: "",
    selectData: {},

    rankModify: false,
    preRankList: [],
    rankList: [],
    duplRank: [],
    selectedRank: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getlinkList({
      pageNum: pager.current
    });
  };

  // 값 변경
  onChangeModal = (value, type) => {
    const tempData = Object.assign({}, this.state.selectData);
    tempData[type] = value;
    this.setState({
      selectData: tempData
    });
  };

  // 삭제
  onDelete = (value, row) => {
    // console.log(JSON.stringify(row, null, 4));
    if (
      global.confirm(
        `${row.name.find((item) => item.language === "ko").content}을(를) ${
          row.deleted ? "복구" : "삭제"
        }하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.linkDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");

          this.getlinkList();
        })
        .catch((error) => {});
    }
  };

  handleChangeRankInput = (value, rowIndex) => {
    this.setState({
      rankList: this.state.rankList.map((rank, index) => {
        if (index === rowIndex) {
          return {
            idx: rank.idx,
            rank: parseInt(value)
          };
        } else {
          return rank;
        }
      })
    });
  };

  handleCheckDuplRank = () => {
    let tempArr = [...this.state.rankList];
    let dupl = [];
    let sortArr = tempArr.sort((a, b) => a.rank - b.rank);
    //table data에서 수정한 rank 검색
    for (var i = 0; i < this.state.rankList.length - 1; i++) {
      if (sortArr[i + 1].rank === sortArr[i].rank) {
        if (i === 0 || sortArr[i - 1].rank !== sortArr[i].rank) {
          dupl.push(sortArr[i]);
        }
        dupl.push(sortArr[i + 1]);
      }
    }
    this.setState({
      duplRank: dupl
    });
    return dupl.length === 0 ? false : true;
  };

  // 랭크 수정
  handleUpdateRank = () => {
    // console.log(JSON.stringify(this.state.rankList, null, 4));
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.linkRankUpdate, [], this.state.rankList)
        .then((res) => {
          showAlert("update");
          this.getlinkList({
            pageNum: this.state.pagination.current
          });
          this.setState({
            rankModify: !this.state.rankModify
          });
        })
        .catch((e) => {});
    }
  };

  handleShowDetail = (params = {}) => {
    this.setState({
      detailVisible: true,
      modalType: params.modalType,
      modalData: params.modalData
    });
  };

  // Dapp Lisk 관리 목록 조회
  getlinkList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getLinkList, [100, pageNum, global.language], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          linkList: res.data.linkList,
          pagination,
          rankList: res.data.linkList.map((value) => {
            return {
              idx: value.idx,
              rank: value.rank
            };
          })
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getlinkList();
  }

  render() {
    const { detailVisible } = this.state;

    let lastRank = 0;
    if (this.state.linkList.length !== 0) {
      this.state.linkList.forEach((value) => {
        if (value.rank > lastRank) lastRank = value.rank;
      });
    }

    const linkColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 80,
        key: "rank",
        title: "순위",
        dataIndex: "rank",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {this.state.rankModify ? (
              <Input
                style={{
                  borderWidth: 1,
                  borderColor: this.state.rankList.map((item, index) => {
                    let bool = false;
                    for (let i = 0; i < this.state.duplRank.length; i++) {
                      if (item.idx === this.state.duplRank[i].idx) {
                        bool = true;
                      }
                    }
                    return bool;
                  })[index]
                    ? "red"
                    : "#d9d9d9"
                }}
                value={this.state.rankList.length !== 0 && this.state.rankList[index].rank}
                onChange={(e) => {
                  if (e.target.value > this.state.lastRank) {
                    alert(`최대 ${this.state.lastRank}을(를) 넘는 숫자를 입력 할 수 없습니다.`);
                  } else {
                    this.setState(
                      {
                        selectedRank: {
                          preRank: this.state.rankList[index].rank,
                          curRank: e.target.value
                        }
                      },
                      () => {
                        this.handleChangeRankInput(this.state.selectedRank.curRank, index);
                      }
                    );
                  }
                }}
              />
            ) : (
              <span>{data}</span>
            )}
          </div>
        )
      },
      {
        key: "name",
        title: "이름",
        dataIndex: "name",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "3px"
              }}>
              <div
                style={{
                  width: "40px",
                  marginRight: "8px"
                }}>
                <img src={row.image} alt="symbolImage" style={{ maxWidth: "100%" }} />
              </div>

              <div>{data[0].content}</div>
            </div>
          </div>
        )
      },
      {
        key: "url",
        title: "URL",
        dataIndex: "url",
        className: "text-center"
      },
      {
        title: "등록일",
        key: "createDate",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <span>{formatDate(data)}</span>
      },
      {
        key: "deleted",
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (value !== row.deleted) {
                  this.onDelete(value, row);
                }
              }}
            />
          </div>
        )
      },
      {
        key: "modify",
        className: "text-center",
        render: (data, row, index) => {
          return (
            <Button
              onClick={() => {
                this.handleShowDetail({
                  modalType: "edit",
                  modalData: row
                });
              }}>
              정보수정
            </Button>
          );
        }
      }
    ];
    return (
      <div>
        <div className="title">DApp Link 관리</div>
        {/* 생성, 수정 화면 */}
        {detailVisible && (
          <LinkDetailModal
            visible={detailVisible}
            modalType={this.state.modalType}
            modalData={this.state.modalData}
            onOk={this.getlinkList}
            onCancel={() => {
              this.setState({
                detailVisible: false,
                modalType: "",
                modalData: {}
              });
            }}
            lastRank={lastRank + 1}
          />
        )}
        <div style={{ float: "left", marginBottom: "10px" }}>
          <Button
            onClick={() => {
              this.handleShowDetail({
                modalType: "create",
                modalData: {}
              });
            }}
            style={{ marginRight: "8px" }}>
            생성
          </Button>
          <Button
            onClick={() => {
              //수정완료버튼 클릭시
              if (this.state.rankModify) {
                //중복 없음
                if (!this.handleCheckDuplRank()) {
                  this.handleUpdateRank();
                } else {
                  alert(`중복된 숫자가 있습니다.`);
                }
              } else {
                //랭크수정 버튼 클릭
                this.setState({
                  rankModify: !this.state.rankModify,
                  preRankList: this.state.rankList
                });
              }
            }}
            style={{ marginRight: "8px" }}>
            {this.state.rankModify ? "수정완료" : "랭크수정"}
          </Button>
          {this.state.rankModify && (
            <Button
              onClick={() => {
                this.setState({
                  rankModify: !this.state.rankModify,
                  selectedRank: {},
                  rankList: this.state.preRankList,
                  duplRank: []
                });
              }}>
              취소
            </Button>
          )}
        </div>

        <div style={{ clear: "both" }} />
        <Table
          id="link-table"
          tableLayout="fixed"
          rowKey={(record) => record.idx}
          columns={linkColumns}
          dataSource={this.state.linkList}
          pagination={false}
        />
      </div>
    );
  }
}
