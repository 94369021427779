import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import locale from "antd/es/date-picker/locale/ko_KR";

import { Table, Button, Select, DatePicker, Checkbox, Icon, Input } from "antd";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  amountFormat,
  checkCoinName,
  checkData
} from "../../util";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";
import fileDownload from "js-file-download";

const { RangePicker } = DatePicker;
const Option = Select.Option;
const Search = Input.Search;
class PaymentLog extends Component {
  state = {
    coinList: [],
    logList: [],
    pagination: {
      current: 1,
      total: 0
    },
    organizationIdx: 0,
    organizationName: "",
    coinType: 0,
    orderByType: "DESC",
    searchText: "",
    startDate: "",
    endDate: "",
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getPaymentLogList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getPaymentLogList();
      }
    );
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      orderByType,
      startDate,
      endDate,
      searchText,
      organizationIdx,
      organizationName,
      coinType,
      pagination,
      isLoaded
    } = this.state;

    let result = [];
    let title = "결제내역 조회";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (coinType !== 0) {
      result.push(`${checkCoinName(coinType)} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    // console.log(`title = ${title}`);
    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.getPaymentLogExcel, [
          total,
          1,
          organizationIdx,
          coinType,
          orderByType,
          startDate,
          endDate,
          searchText
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 결제 내역 조회
  getPaymentLogList = (params = {}) => {
    const {
      organizationIdx,
      coinType,
      orderByType,
      startDate,
      endDate,
      searchText
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPaymentLogList,
      [
        10,
        pageNum,
        organizationIdx,
        coinType,
        orderByType,
        startDate,
        endDate,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          logList: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState(
      {
        coinList: [
          {
            coinType: 0,
            name: "전체"
          },
          ...coinList
        ]
      },
      () => {
        this.getPaymentLogList();
      }
    );
  }

  render() {
    const { coinList, logList } = this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "조직명",
        dataIndex: "organizationName",
        className: "text-center"
      },
      {
        title: "ORDER ID",
        dataIndex: "orderId",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "결제자 (이름 / ID)",
        dataIndex: "userName",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} / {row.userId}
          </div>
        )
      },
      {
        title: "결제 방법",
        dataIndex: "payType",
        className: "text-center",
        render: (data, row) => (
          <div>{string.payType[data] ? string.payType[data] : data}</div>
        )
      },
      {
        title: "결제 금액",
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "취소여부",
        dataIndex: "canceled",
        className: "text-center",
        render: (data) => <div>{data ? "O" : "-"}</div>
      },
      {
        title: "취소날짜",
        dataIndex: "cancelDate",
        className: "text-center",
        render: (data) => <div>{data ? formatDate(data) : "-"}</div>
      }
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          key: "coinType",
          title: "코인 종류",
          dataIndex: "coinType",
          className: "text-center",
          render: (data) => <div>{checkCoinName(data)}</div>
        },
        {
          title: "코인 수량",
          dataIndex: "amount",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "코인 단가",
          dataIndex: "unitPrice",
          className: "text-center",
          render: (data, row) => <div>{numberFormat(data)}</div>
        },
        {
          title: "실지급 코인 종류",
          dataIndex: "payCoinType",
          className: "text-center",
          render: (data) => <div>{checkCoinName(data)}</div>
        },
        {
          title: "실지급 코인 수량",
          dataIndex: "payAmount",
          className: "text-center",
          render: (data, row) => (
            <div>{amountFormat(data, row.payCoinType)}</div>
          )
        },
        {
          title: "페이백 코인 종류",
          dataIndex: "paybackCoinType",
          className: "text-center",
          render: (data) => <div>{checkCoinName(data)}</div>
        },
        {
          title: "페이백 조건",
          dataIndex: "paybackMethod",
          key: "paybackMethod",
          className: "text-center",
          render: (data, row) => (
            <div>{data ? string.paybackTypeString[data] : "-"}</div>
          )
        },
        {
          title: "페이백 비율",
          dataIndex: "paybackRatio",
          key: "paybackRatio",
          className: "text-center",
          render: (data, row) => (
            <div>{data ? `${numberFormat(data)} %` : "-"}</div>
          )
        },
        {
          title: "페이백 금액",
          dataIndex: "paybackAmount",
          key: "paybackAmount",
          className: "text-center",
          render: (data, row) => (
            <div>{data ? amountFormat(data, row.paybackCoinType) : "-"}</div>
          )
        }
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">결제내역 조회</div>
        {this.state.modalVisible && (
          <OrganizationSelectModal
            type="payment"
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false
              });
            }}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <div>
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    modalVisible: true
                  });
                }}
              >
                조직 선택
              </Button>
            </div>
            {this.state.organizationIdx !== 0 && (
              <div className="flex-row flex-center">
                <div>{`가맹점: ${this.state.organizationName}`}</div>
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0
                    });
                  }}
                >
                  <Icon type="close" />
                </Button>
              </div>
            )}
          </div>
          <div className="flex-row flex-center">
            <label className="m-r-10">
              코인 종류&nbsp;
              <Select
                placeholder="코인종류"
                style={{ width: "160px" }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}
              >
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label className="m-r-10">
              순서&nbsp;
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label className="m-r-10">
              기간&nbsp;
              <RangePicker
                // placeholder="시작일"
                locale={locale}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>
            <Search
              placeholder="ORDER ID를 입력하세요"
              enterButton="검색"
              style={{ width: "300px" }}
              onSearch={this.onSearch}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button
            onClick={this.downloadExcel}
            disabled={logList.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={logList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default PaymentLog;
