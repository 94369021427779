import React, { Component } from "react";
import fileDownload from "js-file-download";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Icon, Input, Select, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpPut, httpUrl } from "../../api/httpClient";
import {
  checkCoinName,
  checkData,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startThreeMonthBeforeFormat
} from "../../util";
import string from "../../string";

import SelectBox from "../../components/common/SelectBox";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import CancelList from "../../components/payment/CancelList";
import { showAlert } from "../../components/common/AlertModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;
class PartnersPayment extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      coinList: [],
      list: [],
      pagination: {
        current: 1,
        total: 0
      },
      organizationIdx: 0,
      organizationName: "",
      coinType: 0,
      orderByType: "DESC",
      startDate: startThreeMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      searchText: "",
      statusType: "ALL",
      cancelStatusType: "ALL",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getPaymentLogList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true
    });
  };

  // 결제 상태 선택
  handleChangeStatusType = (value) => {
    this.setState({
      statusType: value,
      isLoaded: true
    });
  };

  // 결제 상태 선택
  handleChangeCancelStatusType = (value) => {
    this.setState({
      cancelStatusType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getPaymentLogList();
      }
    );
  };

  // 결제 취소
  paymentCancel = (row) => {
    if (global.confirm(`${row.orderId} 결제를 취소하시겠습니까?`))
      httpPut(httpUrl.paymentCancel, [row.idx], {})
        .then((res) => {
          // console.log(`res = ${JSON.stringify(r es, null, 4)}`);
          if (res.data.result === "SUCCESS") {
            showAlert("cancel");
            this.getPaymentLogList(this.state.pagination.current);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((e) => {});
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      orderByType,
      startDate,
      endDate,
      searchText,
      statusType,
      cancelStatusType,
      organizationIdx,
      organizationName,
      coinType,
      pagination,
      isLoaded
    } = this.state;

    let result = [];
    let title = "결제 관리";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (coinType !== 0) {
      result.push(`${checkCoinName(coinType)} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    // console.log(`title = ${title}`);
    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.partnerPaymentLogExcel, [
          total,
          1,
          organizationIdx,
          coinType,
          orderByType,
          startDate,
          endDate,
          searchText,
          statusType,
          cancelStatusType
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 결제 내역 조회
  getPaymentLogList = (params = {}) => {
    const {
      organizationIdx,
      coinType,
      orderByType,
      startDate,
      endDate,
      searchText,
      statusType,
      cancelStatusType
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getPartnerPaymentLogList,
      [
        10,
        pageNum,
        organizationIdx,
        coinType,
        orderByType,
        startDate,
        endDate,
        searchText,
        statusType,
        cancelStatusType
      ],
      {}
    )
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState({
      coinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.getPaymentLogList();
  }

  render() {
    const {
      modalVisible,
      cancelVisible,
      cancelList,
      startDate,
      endDate,
      coinList,
      list
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "생성일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "가맹점명",
        dataIndex: "organizationName",
        className: "text-center"
      },
      {
        title: "ORDER ID",
        dataIndex: "orderId",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "결제자 (이름 / ID)",
        dataIndex: "userName",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data} / {row.userId}
          </div>
        )
      },
      {
        title: "결제 방법",
        dataIndex: "payType",
        className: "text-center",
        render: (data, row) => (
          <div>{string.payType[data] ? string.payType[data] : data}</div>
        )
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "결제 금액",
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "결제 상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      },
      {
        title: "취소 여부",
        dataIndex: "canceled",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.cancelStatus !== null
              ? string.requestLogString[row.cancelStatus]
              : data
              ? "O"
              : "-"}
          </div>
        )
      },
      {
        title: "취소날짜",
        dataIndex: "cancelDate",
        className: "text-center",
        render: (data) => <div>{data ? formatDate(data) : "-"}</div>
      },
      {
        title: "취소 목록",
        dataIndex: "cancelList",
        className: "text-center",
        render: (data, row) => (
          <Button
            disabled={row.cancelList.length === 0 ? true : false}
            onClick={() => {
              this.setState({
                cancelVisible: true,
                cancelList: row.cancelList
              });
            }}
          >
            목록조회
          </Button>
        )
      },
      {
        title: "결제 취소",
        dataIndex: "cancelDate",
        className: "text-center",
        render: (data, row) => (
          <div>
            {/* 승인 = REQUSET / 취소 = SUCCESS */}
            {row.status === "SUCCESS" ? (
              <Button
                disabled={row.cancelFlag ? true : false}
                onClick={() => {
                  this.paymentCancel(row);
                }}
              >
                취소
              </Button>
            ) : (
              "-"
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">결제 관리</div>
        {modalVisible && (
          <OrganizationSelectModal
            type="payment"
            visible={modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false
              });
            }}
          />
        )}
        {cancelVisible && (
          <CancelList
            visible={cancelVisible}
            onCancel={() => {
              this.setState({ cancelVisible: false });
            }}
            list={cancelList}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  modalVisible: true
                });
              }}
            >
              가맹점 선택
            </Button>
            {this.state.organizationIdx !== 0 && (
              <div className="flex-row flex-center">
                <div>{`가맹점: ${this.state.organizationName}`}</div>
                <Icon
                  type="close"
                  style={{ color: "red", marginLeft: 5 }}
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex-row fex-center">
            <label className="flex-row flex-center m-r-10">
              코인 종류&nbsp;
              <Select
                placeholder="코인종류"
                style={{ width: "160px", marginRight: "10px" }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}
              >
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label className="flex-row flex-center m-r-10">
              순서&nbsp;
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label className="flex-row flex-center m-r-10">
              결제 상태&nbsp;
              <SelectBox
                value={string.requestLogString[this.state.statusType]}
                codeString={string.requestLogString}
                onChange={(value) => {
                  if (value !== this.state.statusType)
                    this.handleChangeStatusType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label className="flex-row flex-center">
              취소 상태&nbsp;
              <SelectBox
                value={string.requestLogString[this.state.cancelStatusType]}
                codeString={string.requestLogString}
                onChange={(value) => {
                  if (value !== this.state.cancelStatusType)
                    this.handleChangeCancelStatusType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={this.downloadExcel}
            disabled={list.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>

          <div className="flex-row fex-center">
            <label className="flex-row flex-center m-r-10">
              기간&nbsp;
              <RangePicker
                // placeholder="시작일"
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>
            <Search
              placeholder="ORDER ID를 입력하세요"
              enterButton="검색"
              style={{ width: "300px" }}
              onSearch={this.onSearch}
            />
          </div>
        </div>
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PartnersPayment;
