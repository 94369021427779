import React, { Component } from "react";
import fileDownload from "js-file-download";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Icon, Input, Select, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import {
  amountFormat,
  checkCoinName,
  checkData,
  endDateFormat,
  excelFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startThreeMonthBeforeFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Option = Select.Option;
const { RangePicker } = DatePicker;
class SendHistory extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      coinList: [],

      list: [],
      pagination: {
        current: 0,
        total: 0
      },
      coinType: 0,
      requestType: "ALL",
      //searchType: ALL일 경우 searchText: null
      searchType: "ALL",
      transferType: "ALL",
      startDate: startThreeMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      searchText: "",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getTransferLog({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색 날짜 선택
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 요청 조건 선택
  handleChangeRequestType = (value) => {
    this.setState({
      requestType: value,
      isLoaded: true
    });
  };

  // 전송 방법 선택
  handleChangeTransferType = (value) => {
    this.setState({
      transferType: value,
      isLoaded: true
    });
  };

  // 검색 조건 선택
  handleChangeSearchType = (value) => {
    let searchText = "";
    if (value !== "ALL") {
      searchText = this.state.searchText;
    }
    this.setState({
      searchType: value,
      searchText,
      isLoaded: true
    });
  };

  // 검색어 입력
  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value,
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    let {
      isLoaded,
      pagination,
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      transferType
    } = this.state;

    if (isLoaded) {
      alert("검색 후 다시 눌러해주세요.");
    } else {
      let result = [];
      let title = "송금 이력";

      if (startDate !== "" && endDate !== "") {
        result.push(`${startDate} ~ ${endDate} `);
      }

      if (coinType !== 0) {
        result.push(`${checkCoinName(coinType)} `);
      }

      title =
        result.reduce((acc, item, index) => {
          return acc + item;
        }, "") + title;

      let total = pagination.total <= 9 ? 10 : pagination.total;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downloadTokenTransferLogExcel,
          [
            total,
            1,
            coinType,
            requestType,
            searchType,
            startDate,
            endDate,
            searchText,
            transferType
          ],
          {}
        )
          .then((res) => {
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((e) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getTransferLog = (params = {}) => {
    const { coinType, requestType, searchType, startDate, endDate, searchText, transferType } =
      this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getTransferLog,
      [
        10,
        pageNum,
        coinType,
        requestType,
        searchType,
        startDate,
        endDate,
        searchText,
        transferType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState({
      coinList: [
        {
          coinType: 0,
          name: "전체"
        },
        ...coinList
      ]
    });
    this.getTransferLog();
  }

  render() {
    const {
      coinType,
      coinList,
      requestType,
      transferType,
      searchType,
      searchText,
      startDate,
      endDate,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "거래 일시",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        key: "type",
        className: "text-center",
        render: (data) => (
          <div>{string.transferString[data] ? string.transferString[data] : "기타"}</div>
        )
      },
      {
        title: "보낸 주소",
        dataIndex: "from",
        className: "text-center"
      },
      {
        title: "받은 주소",
        dataIndex: "to",
        className: "text-center"
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "txid",
          dataIndex: "txid",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        {
          title: "수수료",
          dataIndex: "fee",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              key: "destinationTag",
              dataIndex: "destinationTag",
              className: "text-center"
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];

      return (
        <Table
          rowKey={(record) => record.txid}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">송금 이력</div>
        <div className="m-b-10 flex-row flex-sb">
          <div className="flex-row flex-center">
            <label>코인 종류&nbsp;</label>
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={coinType}
              onChange={this.handleChangeCoinType}>
              {coinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
            <label>요청 조건&nbsp;</label>
            <SelectBox
              code={string.requestLogCode}
              codeString={string.requestLogString}
              value={string.requestLogString[requestType]}
              onChange={(value) => {
                this.handleChangeRequestType(value);
              }}
              style={{ width: "160px", marginRight: 10 }}
            />
            <label>전송 방법&nbsp;</label>
            <SelectBox
              codeString={string.transferString}
              value={string.transferString[transferType]}
              onChange={(value) => {
                this.handleChangeTransferType(value);
              }}
              style={{ width: "160px" }}
            />
          </div>
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
              <SelectBox
                code={string.walletLogCode}
                codeString={string.transferSearchString}
                value={string.transferSearchString[searchType]}
                onChange={(value) => {
                  this.handleChangeSearchType(value);
                }}
                style={{ width: "160px" }}
              />
            </div>
            <div className="flex-row flex-center m-r-10">
              <Input
                placeholder="주소를 입력해주세요."
                disabled={searchType === "ALL"}
                value={searchText}
                onChange={this.handleChangeSearchText}
                style={{ width: "240px" }}
              />
            </div>
            <Button
              onClick={() => {
                this.getTransferLog();
              }}>
              검색
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>기간&nbsp;</label>
            <RangePicker
              locale={locale}
              allowClear={false}
              format={dateFormat}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default SendHistory;
