import React, { Component } from "react";

import { Modal, Input, Checkbox, Form, Button, Row, Col } from "antd";
import { httpPost, httpPut, httpUrl } from "../../api/httpClient";

import { formatDate, numberFormat } from "../../util";
import { showAlert } from "../common/AlertModal";
import string from "../../string";
import SelectBox from "../common/SelectBox";
import PreviewModal from "./PreviewModal";

import Const from "./../../const";

const FormItem = Form.Item;
const DetailModal = Form.create()(
  class extends Component {
    state = {
      data: {
        title: "",
        content: "",
        checked: false
      }
    };

    onSelect = (value, type, language) => {
      var data = Object.assign({}, this.state.data);

      data[type] = value;
      this.setState({
        data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    // 공지사항 생성
    onNoticeRegistry = () => {
      const { form } = this.props;
      const { data } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            let createData = {
              ...values,
              title: global.lanList.map((lan) => {
                if (lan === "ko") {
                  return {
                    content: values.title,
                    language: lan
                  };
                } else
                  return {
                    content: "",
                    language: lan
                  };
              }),
              content: global.lanList.map((lan) => {
                if (lan === "ko") {
                  return {
                    content: values.content,
                    language: lan
                  };
                } else
                  return {
                    content: "",
                    language: lan
                  };
              }),
              main: data.checked ? 1 : 0
            };
            // console.log(JSON.stringify(createData, null, 4));
            httpPost(httpUrl.noticeCreate, [], createData)
              .then((result) => {
                showAlert("create");
                this.props.onCancel();
                this.props.onOk();
                this.props.form.resetFields();
              })
              .catch((error) => {});
          }
        }
      });
    };

    // 공지사항 수정
    onNoticeUpdate = () => {
      const { data } = this.state;
      const { modalData } = this.props;

      this.props.form.validateFields((err, values) => {
        let updateData = {
          ...modalData,
          title: global.lanList.map((lan) => {
            if (lan === "ko") {
              return {
                content: values.title,
                language: lan
              };
            } else
              return {
                content: "",
                language: lan
              };
          }),
          content: global.lanList.map((lan) => {
            if (lan === "ko") {
              return {
                content: values.content,
                language: lan
              };
            } else
              return {
                content: "",
                language: lan
              };
          }),
          main: data.checked ? 1 : 0
        };
        // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
        if (!err) {
          if (global.confirm(`수정하시겠습니까?`))
            httpPut(httpUrl.noticeUpdate, [], updateData)
              .then((result) => {
                showAlert("update");

                this.props.onCancel();
                this.props.onOk();
              })
              .catch((error) => {});
        }
      });
    };

    // Faq 생성
    onFaqRegistry = () => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            let createData = {
              title: global.lanList.map((lan) => {
                if (lan === "ko") {
                  return {
                    content: values.title,
                    language: lan
                  };
                } else
                  return {
                    content: "",
                    language: lan
                  };
              }),
              content: global.lanList.map((lan) => {
                if (lan === "ko") {
                  return {
                    content: values.content,
                    language: lan
                  };
                } else
                  return {
                    content: "",
                    language: lan
                  };
              })
            };
            // console.log(JSON.stringify(createData, null, 4));
            httpPost(httpUrl.faqCreate, [], createData)
              .then((result) => {
                showAlert("create");
                this.props.onOk();
                this.props.onCancel();
                this.props.form.resetFields();
              })
              .catch((error) => {});
          }
        }
      });
    };

    // FAQ 수정
    onFaqUpdate = () => {
      const { modalData } = this.props;

      this.props.form.validateFields((err, values) => {
        if (!err) {
          let updateData = {
            ...modalData,
            title: global.lanList.map((lan) => {
              if (lan === "ko") {
                return {
                  content: values.title,
                  language: lan
                };
              } else
                return {
                  content: "",
                  language: lan
                };
            }),
            content: global.lanList.map((lan) => {
              if (lan === "ko") {
                return {
                  content: values.content,
                  language: lan
                };
              } else
                return {
                  content: "",
                  language: lan
                };
            })
          };

          if (global.confirm(`수정하시겠습니까?`))
            httpPut(httpUrl.faqUpdate, [], updateData)
              .then((result) => {
                showAlert("update");
                this.props.onOk();
                this.props.onCancel();
                this.props.form.resetFields();
              })
              .catch((error) => {});
        }
      });
    };

    onSubmit = () => {
      const { type, edit } = this.props;

      if (type === "notice") {
        if (edit) this.onNoticeUpdate();
        else this.onNoticeRegistry();
      } else {
        if (edit) this.onFaqUpdate();
        else this.onFaqRegistry();
      }
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    componentDidMount() {
      const { modalData, edit, form } = this.props;

      if (edit) {
        form.setFieldsValue({
          ...modalData,
          title:
            modalData.title.find((item) => item.language === "ko") &&
            modalData.title.find((item) => item.language === "ko").content,
          content:
            modalData.content.find((item) => item.language === "ko") &&
            modalData.content.find((item) => item.language === "ko").content
        });

        this.setState({
          data: {
            ...modalData,
            title: modalData.title.map((item) => item.language === "ko")
              .content,
            content: modalData.content.map((item) => item.language === "ko")
              .content,
            checked: modalData.main ? true : false
          }
        });
      }
    }

    render() {
      const { data, checked, preview, previewData } = this.state;
      const { visible, type, form, edit } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={type === "notice" ? "공지사항" : "FAQ"}
          okText={edit ? "수정" : "생성"}
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={this.onCancel}
          style={{ minWidth: "700px" }}
        >
          {/* <div>{JSON.stringify(modalData)}</div> */}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            {edit && (
              <Row
                className="flex-row flex-center flex-sb "
                style={{
                  paddingLeft: "100px",
                  color: "black",
                  marginBottom: "20px"
                }}
              >
                <Col span={18}>
                  등록일&nbsp;:&nbsp;&nbsp;
                  {formatDate(data.createDate)}
                </Col>
                <Col span={6}>
                  조회수&nbsp;:&nbsp;&nbsp;
                  {numberFormat(data.read)}
                </Col>
              </Row>
            )}
            {type === "notice" && (
              <FormItem label={<span>구분&nbsp;</span>}>
                {getFieldDecorator("category", {
                  rules: [
                    {
                      required: true,
                      message: "카테고리를 선택해주세요!"
                    }
                  ]
                })(
                  <SelectBox
                    type="category"
                    placeholder="선택해주세요"
                    code={string.noticeSelectCode}
                    codeString={string.noticeSelect}
                    onChange={this.onSelect}
                    style={{ width: "200px" }}
                    disable={edit ? true : false}
                  />
                )}
                <Checkbox
                  style={{ float: "right" }}
                  onChange={(e) => {
                    this.onSelect(e.target.checked, "checked");
                  }}
                  value={checked}
                >
                  메인 노출
                </Checkbox>
              </FormItem>
            )}
            <FormItem label={<span>제목&nbsp;</span>}>
              {getFieldDecorator("title", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "제목을 입력해주세요!"
                  }
                ]
              })(<Input allowClear />)}
            </FormItem>
            <FormItem key={`content`} label={<span>내용&nbsp;</span>}>
              {getFieldDecorator(`content`, {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "내용을 입력해주세요!"
                  }
                ]
              })(<Input.TextArea rows={8} />)}
            </FormItem>
            {Const.isPreviewFlag && type === "notice" && (
              <FormItem colon={false} label={<span>&nbsp;</span>}>
                <Button
                  // disabled={
                  //   data.content.find((item) => item.language === "ko").content !== "" ? false : true
                  // }
                  onClick={() => {
                    if (data.content !== "")
                      this.setState({
                        preview: true,
                        previewData: form.getFieldValue("content")
                      });
                  }}
                >
                  미리보기
                </Button>
              </FormItem>
            )}

            {preview && (
              <PreviewModal
                visible={preview}
                data={previewData}
                onCancel={() => {
                  this.setState({
                    preview: false
                  });
                }}
              />
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default DetailModal;
