import React, { Component } from "react";
import fileDownload from "js-file-download";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, DatePicker, Button, Select, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import {
  numberFormat,
  dayFormat,
  amountFormat,
  statFormat,
  checkCoinName,
  statMonthBeforeFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Option = Select.Option;
const { RangePicker } = DatePicker;
class TokenTransferStatistics extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      coinList: [],

      list: [],
      pagination: {
        total: 0,
        current: 0
      },
      periodType: "DAY",
      startDate: statMonthBeforeFormat(date),
      endDate: statFormat(date),
      coinType: 0,
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getTransferStatisticsList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색 종류 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { isLoaded, pagination, periodType, startDate, endDate, coinType } = this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.tokenTransferStatisticsListExcel,
          [total, 1, periodType, startDate, endDate, coinType],
          {}
        )
          .then((res) => {
            fileDownload(
              res,
              `${startDate} ~ ${endDate} ${
                coinType === 0 ? "전체" : `${checkCoinName(coinType)}`
              } ${string.partnerStatistics[periodType]} 거래 통계.xlsx`
            );
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 거리 통계 목록 조회
  getTransferStatisticsList = (params = {}) => {
    const { periodType, startDate, endDate, coinType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.tokenTransferStatisticsList,
      [10, pageNum, periodType, startDate, endDate, coinType],
      {}
    )
      .then((res) => {
        console.log(`token statistics data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState(
      {
        coinList: [
          {
            coinType: 0,
            name: "전체"
          },
          ...coinList
        ]
      },
      () => {
        this.getTransferStatisticsList();
      }
    );
  }

  render() {
    const { coinList, periodType, startDate, endDate, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: () => (
          <div>
            <div>내부 거래</div>
            <div>(건수 / 거래량)</div>
          </div>
        ),
        dataIndex: "count",
        className: "text-center",
        render: (data, row) => (
          <div>
            {numberFormat(data)} / {amountFormat(row.amount, row.coinType)}
          </div>
        )
      },
      {
        title: () => (
          <div>
            <div>외부 입금 </div>
            <div>(건수 / 거래량)</div>
          </div>
        ),
        dataIndex: "externalInCount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {numberFormat(data)} / {amountFormat(row.externalInAmount, row.coinType)}
          </div>
        )
      },
      {
        title: () => (
          <div>
            <div>외부 출금 </div>
            <div>(건수 / 거래량)</div>
          </div>
        ),
        dataIndex: "externalOutCount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {numberFormat(data)} / {amountFormat(row.externalOutAmount, row.coinType)}
          </div>
        )
      },
      {
        title: () => (
          <div>
            <div>결제 </div>
            <div>(건수 / 거래량)</div>
          </div>
        ),
        dataIndex: "paymentCount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {numberFormat(data)} / {amountFormat(row.paymentAmount, row.coinType)}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">거래 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-end m-b-10">
          <label className="flex-row flex-center m-r-10">
            코인 종류&nbsp;
            <Select
              placeholder="코인종류"
              style={{ width: "160px", marginRight: "10px" }}
              value={this.state.coinType}
              onChange={this.handleChangeCoinType}>
              {coinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
          </label>
          <label className="flex-row flex-center m-r-10">
            주기&nbsp;&nbsp;
            <SelectBox
              style={{ width: "160px" }}
              value={string.partnerStatistics[periodType]}
              codeString={string.partnerStatistics}
              onChange={this.handleChangePeriodType}
            />
          </label>
          <label className="flex-row flex-center m-r-10">
            기간&nbsp;&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              format={dateFormat}
              allowClear={false}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>
          <Button
            type="primary"
            onClick={() => {
              this.getTransferStatisticsList();
            }}>
            검색
          </Button>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="transfer-table"
          rowKey={(record) => `${record.statDate}${record.coinType}`}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default TokenTransferStatistics;
