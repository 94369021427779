import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Button, DatePicker, Icon, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";
import {
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";

const Search = Input.Search;
const { RangePicker } = DatePicker;
class AdministratorAudit extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: {
        current: 0,
        total: 0
      },
      searchText: "",
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      auditResultType: "ALL",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getAdministratorAudit({ pageNum: pager.current });
    }
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 결과 종류 선택
  handleChangeResult = (value) => {
    this.setState({
      auditResultType: value,
      isLoaded: true
    });
  };

  // 검색어 입력
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getAdministratorAudit();
      }
    );
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { isLoaded, pagination, searchText, startDate, endDate, auditResultType } = this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.getAdministratorAuditExcel,
          [total, 1, searchText, startDate, endDate, auditResultType],
          {}
        )
          .then((res) => {
            fileDownload(res, `${startDate} ~ ${endDate} 관리자 감사.xlsx`);
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getAdministratorAudit = (params = {}) => {
    const { searchText, startDate, endDate, auditResultType } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getAdministratorAudit,
      [10, pageNum, searchText, startDate, endDate, auditResultType],
      {}
    )
      .then((res) => {
        // console.log(`admin audit data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getAdministratorAudit();
  }

  render() {
    const { startDate, endDate, auditResultType, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        width: 100,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "계정",
        dataIndex: "admin",
        className: "text-center"
      },
      {
        width: 100,
        title: "활동",
        dataIndex: "action",
        className: "text-center"
      },
      {
        title: "요청",
        dataIndex: "request",
        className: "text-center"
        // render: (data) => <div style={{ maxWidth: 800 }}>{data}</div>
      },
      {
        title: "결과",
        dataIndex: "result",
        className: "text-center"
      },
      {
        title: "일시",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      }
    ];

    return (
      <div>
        <div className="title">관리자 감사</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            검색기간&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={false}
            />
          </label>
          <label className="m-r-10">
            결과 종류&nbsp;
            <SelectBox
              value={string.auditString[auditResultType]}
              code={string.auditCode}
              codeString={string.auditString}
              onChange={this.handleChangeResult}
            />
          </label>
          <Search
            placeholder="계정을 입력해주세요."
            enterButton="검색"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 400 }}
          />
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }
}

export default AdministratorAudit;
