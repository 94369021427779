import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, DatePicker, Button, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { dayFormat, statFormat, statMonthBeforeFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";

const { RangePicker } = DatePicker;
class VirtualAccountStatistics extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: {
        current: 0,
        total: 0
      },
      periodType: "DAY",
      startDate: statMonthBeforeFormat(date),
      endDate: statFormat(date),
      organizationIdx: 0,
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getVirtualAccountStatisticsList({ pageNum: pager.current });
    }
  };

  // 검색 종류 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      isLoaded,
      pagination,
      periodType,
      startDate,
      endDate,
      organizationIdx,
      organizationName
    } = this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.virtualAccountStatisticsListExcel,
          [total, 1, periodType, startDate, endDate, organizationIdx],
          {}
        )
          .then((res) => {
            fileDownload(
              res,
              `${startDate} ~ ${endDate} ${
                organizationIdx === 0 ? "전체" : `${organizationName}`
              } ${string.partnerStatistics[periodType]} 임시지갑생성통계.xlsx`
            );
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 임시지갑통계
  getVirtualAccountStatisticsList = (params = {}) => {
    const { periodType, startDate, endDate, organizationIdx } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.virtualAccountStatisticsList,
      [10, pageNum, periodType, startDate, endDate, organizationIdx],
      {}
    )
      .then((res) => {
        // console.log(`virtualaccount statistics data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getVirtualAccountStatisticsList();
  }

  render() {
    const { periodType, startDate, endDate, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "가맹점명",
        dataIndex: "orgName",
        className: "text-center"
      },
      {
        title: "생성 수량",
        dataIndex: "count",
        className: "text-center"
      },
      {
        title: "사용",
        dataIndex: "used",
        className: "text-center"
      },
      {
        title: "미사용",
        dataIndex: "unused",
        className: "text-center"
      }
    ];

    return (
      <div>
        {this.state.modalVisible && (
          <OrganizationSelectModal
            type="payment"
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false
              });
            }}
          />
        )}
        <div className="title">임시 지갑 생성 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  modalVisible: true
                });
              }}>
              가맹점 선택
            </Button>
            {this.state.organizationIdx !== 0 && (
              <div className="flex-row flex-center">
                <div>{`가맹점: ${this.state.organizationName}`}</div>
                <Icon
                  type="close"
                  style={{ color: "red", marginLeft: 5 }}
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0,
                      isLoaded: true
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex-row flex-center">
            <label className="flex-row flex-center m-r-10">
              주기&nbsp;&nbsp;
              <SelectBox
                style={{ width: "160px" }}
                value={string.partnerStatistics[periodType]}
                codeString={string.partnerStatistics}
                onChange={this.handleChangePeriodType}
              />
            </label>
            <label className="flex-row flex-center m-r-10">
              기간&nbsp;&nbsp;
              <RangePicker
                locale={locale}
                allowClear={false}
                format={dateFormat}
                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>
            <Button
              type="primary"
              onClick={() => {
                this.getVirtualAccountStatisticsList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          columns={columns}
          rowKey={(record) => record.idx}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default VirtualAccountStatistics;
