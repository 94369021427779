import React, { Component } from "react";

import { Modal, Upload, Form, Button, Icon, Input, Row, Col, InputNumber, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpUrl, serverUrl, httpPost } from "../../api/httpClient";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { coinMultiFormat, formatDateSecond } from "../../util";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showAlert } from "../../components/common/AlertModal";

const FormItem = Form.Item;

const LockManual = Form.create()(
  class extends Component {
    state = {
      coinType: 0
    };

    // 코인 종류 선택
    coinTypeHandleChange = (value) => {
      this.setState({
        coinType: value
      });
    };

    // 배포
    onSubmit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
          // console.log(`err = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...values,
            amount: coinMultiFormat(values.amount, values.coinType),
            releaseDate: formatDateSecond(values.releaseDate),
            transferFlag: true
          };
          if (global.confirm("코인 배포를 하시겠습니까?")) {
            httpPost(httpUrl.dailyLockSet, [], body)
              .then((res) => {
                if (res.data.result !== "FAIL") {
                  showAlert("send");
                  this.props.form.resetFields();
                  this.setState({
                    coinType: 0
                  });
                } else ShowErrorAlert(res.data.data);
              })
              .catch((error) => {});
          }
          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
        }
      });
    };

    render() {
      const { coinType } = this.state;
      const { getFieldDecorator } = this.props.form;

      const props = {
        action: serverUrl + httpUrl.dailyLockSetExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm("배포 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s = "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "배포결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 배포\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => <div>{value}</div>)}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {}
          });
          // if (file.data.failAddresses.length > 0) {
          //   file.data.failAddresses = file.data.failAddresses.map(
          //     value => "\n" + value
          //   );
          //   s = s + "- 실패한 Card" + file.data.failAddresses;
          // }
          // alert(s);
        },
        onError(err) {
          Modal.error({
            title: "배포결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "#000",
              textAlign: "center",
              marginBottom: "30px",
              fontWeight: "bold"
            }}>
            LOCK 배포
          </div>
          <div className="wrapper mt-35 width-1240" />

          <div
            style={{
              maxWidth: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span></span>} colon={false}>
                <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                  <Button style={{ marginRight: "20px" }}>
                    <Icon type="upload" style={{ fontSize: "20px" }} /> 파일 업로드
                  </Button>
                </Upload>
                <a href={"/setDailyLockExcelFomat.xlsx"} download>
                  <Button>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                    양식 다운로드
                  </Button>
                </a>
                <span style={{ color: "blue", marginLeft: "10px" }}>
                  * 여러 사람에게 배포 시 파일업로드 사용
                </span>
              </FormItem>
              {/* <FormItem label={<span></span>} colon={false}></FormItem> */}
              <div className="flex-row flex-center justify-center" style={{ color: "blue" }}>
                코인을 배포하면서 락을 설정합니다.
              </div>
              <FormItem label={<span>coinType&nbsp;</span>}>
                <Row>
                  <Col span={6}>
                    {getFieldDecorator("coinType", {
                      rules: [
                        {
                          required: true,
                          message: "코인 종류를 선택해주세요!"
                        }
                      ]
                    })(
                      <CoinSelectBox
                        onChange={this.coinTypeHandleChange}
                        placeholder="선택해주세요."
                        style={{ width: "160px" }}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    {coinType === 3 && (
                      <FormItem style={{ marginBottom: 0 }}>
                        {getFieldDecorator("destinationTag", {
                          rules: [
                            {
                              required: true,
                              message: "destinationTag를 입력해주세요."
                            }
                          ]
                        })(
                          <Input
                            allowClear={true}
                            placeholder="destinationTag"
                            style={{ width: "300px" }}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              </FormItem>
              <FormItem label={<span>address&nbsp;</span>}>
                {getFieldDecorator("address", {
                  rules: [
                    {
                      required: true,
                      message: "주소를 입력해주세요!"
                    }
                  ]
                })(
                  <Input
                    allowClear={true}
                    style={{ width: "360px" }}
                    placeholder="주소를 입력해주세요"
                  />
                )}
              </FormItem>
              <FormItem label={<span>amount&nbsp;</span>}>
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      message: "amount를 입력해주세요!"
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && coinType === 0) {
                          cb("코인 종류를 선택해주세요.");
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <InputNumber
                    allowClear
                    formatter={(value) =>
                      `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "300px" }}
                    placeholder="수량을 입력해주세요."
                  />
                )}
              </FormItem>
              <FormItem label={<span>해제일&nbsp;</span>}>
                {getFieldDecorator("releaseDate", {
                  rules: [
                    {
                      required: true,
                      message: "해제일을 선택해주세요!"
                    }
                  ]
                })(
                  <DatePicker
                    style={{ width: "200px" }}
                    placeholder="해제일을 선택해주세요."
                    disabledDate={this.disabledDate}
                    locale={locale}
                  />
                )}
              </FormItem>
              <FormItem label={<span>해제기간&nbsp;</span>}>
                {getFieldDecorator("releaseDays", {
                  rules: [
                    {
                      required: true,
                      message: "해제기간을 선택해주세요!"
                    }
                  ]
                })(<InputNumber allowClear style={{ width: "200px" }} placeholder="10" />)}
              </FormItem>
              <FormItem label={<span></span>} colon={false}>
                <div style={{ color: "blue" }}>주소, 수량, 해제일, 해제기간</div>
                <div style={{ color: "blue" }}>a, 100, 2022-08-15, 10</div>
                <div style={{ color: "blue" }}>
                  a주소에 8-15일부터 10일간 매일 100/10 개의 락이 해제된다.
                </div>
              </FormItem>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  보내기
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      );
    }
  }
);

export default LockManual;
