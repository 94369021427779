import React, { Component } from "react";

import { Modal, Input, Checkbox, Form, Button, Row, Col } from "antd";
import { httpPost, httpPut, httpUrl } from "../../api/httpClient";

import { formatDate, numberFormat } from "../../util";
import { showAlert } from "../common/AlertModal";
import string from "../../string";
import SelectBox from "../common/SelectBox";
import PreviewModal from "./PreviewModal";

import Const from "../../const";

const FormItem = Form.Item;
const MultiDetailModal = Form.create()(
  class extends Component {
    state = {
      data: {
        title: global.lanList.map((lan) => {
          return {
            content: "",
            language: lan
          };
        }),
        content: global.lanList.map((lan) => {
          return {
            content: "",
            language: lan
          };
        }),
        checked: false
      }
    };

    onSelect = (value, type, language) => {
      var data = Object.assign({}, this.state.data);
      let arr = data[type];

      if (type === "title") {
        arr = arr.map((item) => {
          if (item.language === language) {
            return { content: value, language: language };
          } else return item;
        });
        data.title = arr;
      } else if (type === "content") {
        arr = arr.map((item) => {
          if (item.language === language) {
            return { content: value, language: language };
          } else return item;
        });
        data.content = arr;
      } else data[type] = value;

      this.setState({
        data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    // 공지사항 생성
    onNoticeRegistry = () => {
      const { form } = this.props;
      const { data } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            let createData = {
              ...data,
              main: data.checked ? 1 : 0
            };
            // console.log(JSON.stringify(createData, null, 4));
            httpPost(httpUrl.noticeCreate, [], createData)
              .then((result) => {
                showAlert("create");
                this.props.onCancel();
                this.props.onOk();
                this.props.form.resetFields();
              })
              .catch((error) => {});
          }
        }
      });
    };

    // 공지사항 수정
    onNoticeUpdate = () => {
      const { data } = this.state;
      const { modalData } = this.props;

      let updateData = {
        ...modalData,
        ...data,
        main: data.checked ? 1 : 0
      };
      // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);

      if (global.confirm(`수정하시겠습니까?`))
        httpPut(httpUrl.noticeUpdate, [], updateData)
          .then((result) => {
            showAlert("update");

            this.props.onCancel();
            this.props.onOk();
          })
          .catch((error) => {});
    };

    // Faq 생성
    onFaqRegistry = () => {
      const { form } = this.props;
      const { data } = this.state;

      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm(`생성하시겠습니까?`)) {
            // createData = {
            //   title: registryData.title,
            //   content: registryData.content
            // };
            // console.log(JSON.stringify(registryData, null, 4));
            httpPost(httpUrl.faqCreate, [], data)
              .then((result) => {
                showAlert("create");
                this.props.onOk();
                this.props.onCancel();
                this.props.form.resetFields();
              })
              .catch((error) => {});
          }
        }
      });
    };

    // FAQ 수정
    onFaqUpdate = () => {
      const { data } = this.state;
      const { modalData } = this.props;

      let updateData = {
        ...modalData,
        ...data
      };

      if (global.confirm(`수정하시겠습니까?`))
        httpPut(httpUrl.faqUpdate, [], updateData)
          .then((result) => {
            showAlert("update");
            this.props.onOk();
            this.props.onCancel();
            this.props.form.resetFields();
          })
          .catch((error) => {});
    };

    onSubmit = () => {
      const { type, edit } = this.props;

      if (type === "notice") {
        if (edit) this.onNoticeUpdate();
        else this.onNoticeRegistry();
      } else {
        if (edit) this.onFaqUpdate();
        else this.onFaqRegistry();
      }
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    componentDidMount() {
      const { modalData, edit, form } = this.props;

      if (edit) {
        form.setFieldsValue({
          ...modalData,
          title: modalData.title.map((item) => {
            return item.content;
          }),
          content: modalData.content.map((item) => {
            return item.content;
          })
        });

        this.setState({
          data: {
            ...modalData,
            checked: modalData.main ? true : false
          }
        });
      }
    }

    render() {
      const { data, preview, previewData } = this.state;
      const { visible, type, form, edit } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={type === "notice" ? "공지사항" : "FAQ"}
          okText={edit ? "수정" : "생성"}
          cancelText="취소"
          onOk={this.onSubmit}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          {/* <div>{JSON.stringify(modalData)}</div> */}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            {edit && (
              <Row
                className="flex-row flex-center flex-sb "
                style={{
                  paddingLeft: "100px",
                  color: "black",
                  marginBottom: "20px"
                }}>
                <Col span={18}>
                  등록일&nbsp;:&nbsp;&nbsp;
                  {formatDate(data.createDate)}
                </Col>
                <Col span={6}>
                  조회수&nbsp;:&nbsp;&nbsp;
                  {numberFormat(data.read)}
                </Col>
              </Row>
            )}
            {type === "notice" && (
              <FormItem label={<span>구분&nbsp;</span>}>
                {getFieldDecorator("category", {
                  rules: [
                    {
                      required: true,
                      message: "카테고리를 선택해주세요!"
                    }
                  ]
                })(
                  <SelectBox
                    type="category"
                    placeholder="선택해주세요"
                    code={string.noticeSelectCode}
                    codeString={string.noticeSelect}
                    onChange={this.onSelect}
                    style={{ width: "200px" }}
                    disable={edit ? true : false}
                  />
                )}
                <Checkbox
                  style={{ float: "right" }}
                  onChange={(e) => {
                    this.onSelect(e.target.checked, "checked");
                  }}
                  checked={data.checked}>
                  메인 노출
                </Checkbox>
              </FormItem>
            )}
            {global.lanList.map((lan, index) => (
              <div key={index}>
                <FormItem key={`title${lan}${index}`} label={<span>제목({lan})&nbsp;</span>}>
                  {getFieldDecorator(`title[${index}]`, {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: lan === "ko" ? true : false,
                        message: "제목을 입력해주세요!"
                      }
                    ]
                  })(
                    <Input
                      allowClear
                      onChange={(e) => {
                        this.onSelect(e.target.value, "title", lan);
                      }}
                    />
                  )}
                </FormItem>
                <FormItem key={`content${lan}${index}`} label={<span>내용({lan})&nbsp;</span>}>
                  {getFieldDecorator(`content[${index}]`, {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: lan === "ko" ? true : false,
                        message: "내용을 입력해주세요!"
                      }
                    ]
                  })(
                    <Input.TextArea
                      rows={4}
                      value={data.content.find((item) => item.language === lan).content}
                      onChange={(e) => {
                        this.onSelect(e.target.value, "content", lan);
                      }}
                    />
                  )}
                  {Const.isPreviewFlag && type === "notice" && (
                    <Button
                      disabled={
                        data.content.find((item) => item.language === lan).content !== ""
                          ? false
                          : true
                      }
                      onClick={() => {
                        if (data.content.find((item) => item.language === lan).content !== "")
                          this.setState({
                            preview: true,
                            previewData: data.content.find((item) => item.language === lan).content
                          });
                      }}>
                      미리보기
                    </Button>
                  )}
                </FormItem>
              </div>
            ))}
          </Form>
          {preview && (
            <PreviewModal
              visible={preview}
              data={previewData}
              onCancel={() => {
                this.setState({
                  preview: false
                });
              }}
            />
          )}
        </Modal>
      );
    }
  }
);

export default MultiDetailModal;
