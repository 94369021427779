import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Button, DatePicker, Icon, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpPut, httpUrl } from "../../api/httpClient";
import string from "../../string";
import {
  amountFormat,
  checkCoinName,
  dayFormat,
  numberFormat,
  statFormat,
  statMonthBeforeFormat
} from "../../util";
import SelectBox from "../../components/common/SelectBox";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";
import { showAlert } from "../../components/common/AlertModal";

const { RangePicker } = DatePicker;
class PartnersStatistics extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      partnersList: [],

      list: [],
      pagination: {
        current: 0,
        total: 0
      },
      partnerIdx: 0,
      periodType: "DAY",
      status: "ALL",
      startDate: statMonthBeforeFormat(date),
      endDate: statFormat(date),
      // startDate: "",
      // endDate: "",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager
      });

      this.getPaymentStatistics({ pageNum: pager.current });
    }
  };

  // 주기 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value,
      isLoaded: true
    });
  };

  // 정산 상태
  handleChangeStatus = (value) => {
    this.setState({
      status: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  onChange = (date, dateString) => {
    // console.log(`date = ${JSON.stringify(date, null, 4)}`);
    this.setState({
      startDate: date.length !== 0 ? statFormat(date[0]) : "",
      endDate: date.length !== 0 ? statFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 정산하기
  onUpdateStatus = (value, data) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      let checkData = { ...data, type: this.state.periodType };
      if (
        global.confirm(
          `${data.organizationName}의 ${dayFormat(
            data.statDate
          )} 정산을 하시겠습니까?`
        )
      )
        httpPut(httpUrl.paymentCheck, [], checkData)
          .then((res) => {
            if (res.data) {
              showAlert("payment");
              this.getPaymentStatistics({
                pageNum: this.state.pagination.current
              });
            }
            // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          })
          .catch((e) => {});
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      isLoaded,
      pagination,
      partnerIdx,
      periodType,
      status,
      startDate,
      endDate
    } = this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.paymentStatisticsExcel,
          [total, 1, partnerIdx, periodType, status, startDate, endDate],
          {}
        )
          .then((res) => {
            fileDownload(res, `${startDate} ~ ${endDate} 정산 관리.xlsx`);
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  getPaymentStatistics = (params = {}) => {
    let pageNum = params.pageNum || 1;
    const { partnerIdx, periodType, status, startDate, endDate } = this.state;

    httpGet(
      httpUrl.paymentStatistics,
      [10, pageNum, partnerIdx, periodType, status, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getPaymentStatistics();
  }

  render() {
    const {
      visible,
      partnerIdx,
      periodType,
      status,
      startDate,
      endDate,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        title: "일시",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "가맹점명",
        dataIndex: "organizationName",
        className: "text-center"
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "결제건수",
        dataIndex: "count",
        className: "text-center"
      },
      {
        title: "결제수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "결제금액(원)",
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "평균단가(원)",
        dataIndex: "unitPrice",
        className: "text-center",
        render: (data, row) => (
          <div>
            {Number.isInteger(data)
              ? numberFormat(data)
              : numberFormat(data.toFixed(3))}
          </div>
        )
      },
      {
        title: "정산 상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => (
          <div>
            {row.status === "SUCCESS" ? (
              string.partnerStatisticsStatus[data]
            ) : (
              <SelectBox
                value={string.partnerStatisticsStatus[data]}
                codeString={string.successStatus}
                onChange={(value) => this.onUpdateStatus(value, row)}
              />
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {visible && (
          <OrganizationSelectModal
            type="payment"
            visible={visible}
            onOk={(idx, name) => {
              this.setState({
                partnerIdx: idx,
                organizationName: name,
                isLoaded: true
              });
            }}
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
          />
        )}
        <div className="title">정산 관리</div>
        <div style={{ clear: "both" }} />
        <div className="flex-row flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  visible: true
                });
              }}
            >
              가맹점 선택
            </Button>
            {partnerIdx !== 0 && (
              <div className="flex-row flex-center">
                <div>{`가맹점: ${this.state.organizationName}`}</div>
                <Icon
                  type="close"
                  style={{ color: "red", marginLeft: 5 }}
                  onClick={() => {
                    this.setState({
                      partnerIdx: 0
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex-row flex-center">
            <label className="flex-row flex-center m-r-10">
              주기&nbsp;&nbsp;
              <SelectBox
                style={{ width: "160px" }}
                value={string.partnerStatistics[periodType]}
                codeString={string.partnerStatistics}
                onChange={this.handleChangePeriodType}
              />
            </label>
            <label className="flex-row flex-center m-r-10">
              정산 상태&nbsp;&nbsp;
              <SelectBox
                style={{ width: "160px" }}
                value={string.partnerStatisticsStatus[status]}
                codeString={string.partnerStatisticsStatus}
                onChange={this.handleChangeStatus}
              />
            </label>
            <label className="flex-row flex-center">
              검색 기간&nbsp;&nbsp;
              <RangePicker
                locale={locale}
                allowClear={false}
                format={dateFormat}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                onChange={this.onChange}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.getPaymentStatistics();
                }}
              >
                검색
              </Button>
            </label>
          </div>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button
            onClick={this.downloadExcel}
            disabled={list.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }
}

export default PartnersStatistics;
