import React, { Component } from "react";
import { Modal, Table, Input, Button, Tag } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat } from "../../util";

const Search = Input.Search;

class UserList extends Component {
  constructor(props) {
    super(props);
    this.fetch = () => {};
    this.state = {
      userList: [],
      pagination: {
        current: 1,
        total: 0
      },
      searchText: "",

      franchise: false,
      selectedUser: {}
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { type } = this.props;
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    if (type) {
      type === "user"
        ? this.getUserList({ pageNum: pager.current })
        : type === "organization"
        ? this.getRecommenderList({ pageNum: pager.current })
        : this.getFranchiseList({ pageNum: pager.current });
    }
  };

  onSearch = (value) => {
    const { type } = this.props;
    this.setState(
      {
        searchText: value
      },
      () => {
        type === "user" ? this.getUserList() : this.getRecommenderList();
      }
    );
  };

  onOk = () => {
    this.props.onOk(this.state.selectedUser);
    this.props.onCancel();
  };

  onChangeTag = (value) => {
    this.setState({ selectedUser: value });
  };

  getUserList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getUserList, [10, pageNum, false, searchText], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          userList: res.data.userList,
          pagination
        });
      })
      .catch((error) => {});
  };

  getRecommenderList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getRecommenderList,
      [10, pageNum, this.props.userIdx, searchText],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          franchise: true,
          userList: res.data.list,
          pagination
        });
      })
      .catch((error) => {});
  };

  getFranchiseList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getFranchiseQr, [10, pageNum], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          userList: res.data.list,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    const { type } = this.props;
    if (type) {
      if (type === "user") this.getUserList();
      if (type === "organization") this.getRecommenderList();
      if (type === "franchise") this.getFranchiseList();
    }
  }

  render() {
    const { type, visible, onCancel } = this.props;
    const { franchise, selectedUser, userList } = this.state;

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      type === "user"
        ? {
            title: "아이디",
            dataIndex: type ? "id" : "userId",
            key: "id",
            className: "text-center"
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: type ? "이름" : "조직명",
        dataIndex: "name",
        key: "name",
        className: "text-center"
      },
      franchise
        ? {
            title: "가맹점 여부",
            dataIndex: "franchise",
            key: "franchise",
            className: "text-center",
            render: (data) => <div>{data ? "O" : "X"}</div>
          }
        : {
            colSpan: 0,
            width: 0,
            render: () => {
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          },
      {
        title: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.props.onOk(row, this.props.type ? "user" : "parentId");
              this.props.onCancel();
              // this.onChangeTag(row);
            }}>
            선택
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={type ? "회원 정보" : "조직 정보"}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        footer={false}>
        {selectedUser.idx !== undefined && (
          <Tag key={selectedUser.idx}>
            {type ? selectedUser.id : selectedUser.userId} /{" "}
            {selectedUser.name === null || selectedUser.name === ""
              ? "-"
              : selectedUser.name}
          </Tag>
        )}
        {this.props.type !== "franchise" && (
          <div className="flex-row flex-center flex-end m-b-10">
            <Search
              placeholder={
                type ? "아이디를 입력해주세요." : "조직명을 검색해주세요."
              }
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />
          </div>
        )}
        <Table
          id="member-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={userList}
          style={{ marginBottom: "10px" }}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}
export default UserList;
