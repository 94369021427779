export default {
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "58545",
  // isFoundationInFee: false,  // 플랫타 구분
  // isPurchaseInFee: false,    // 구매 구분
  // isP2PFlag: false           //  p2p 구분
  // isLogoFlag: false,         // 로고 이미지
  // isOrganizationFlag: false  // 조직 구분
  // isPreviewFlag: false       // 공지 미리보기 조건

  /* test */
  // serverProtocol: "http",
  // serverPort: "58546",
  // clientPort: "15002",
  /* prod */
  serverProtocol: "https",
  serverPort: "48545",
  clientPort: "15001",

  // test
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "test.back.blobs.info",
  // clientId: "r06yvi25vl",   // naver map client key
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // linkus
  // isFoundationInFee: true,
  // isPurchaseInFee: false,
  // serverIp: "www.linkuswallet.com",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // flata
  // isFoundationInFee: true,
  // isPurchaseInFee: false,
  // serverIp: "api.flata.org",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: true

  // center wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: true,
  // serverIp: "centereth.com",
  // naver map client key
  // clientId: "r06?yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // k-pap wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.k-pap.com",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // key-shop wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.ssportpay.com",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // SB wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.sbpay.co.kr",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isPreviewFlag: false

  // mtrax
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.mtrax.io",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // exs wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.orangeentertainment.co.kr",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // withblock
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.barobiz.net",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ"
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // ITS wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.itsprotocol.net",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-its.png"),
  // logoImageWhite: require("../src/img/logo-its-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // GOMONEDA
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.gomoneda.com",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false,
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // swapcone wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.swapcone.net",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-swap.png"),
  // logoImageWhite: require("../src/img/logo-swap-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // our-pay wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.ourpaywallet.com",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-ourpay.png"),
  // logoImageWhite: require("../src/img/logo-ourpay-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // payturn wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.payturn.net",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-payturn.png"),
  // logoImageWhite: require("../src/img/logo-payturn-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // edus wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "eserver.edus.io",
  // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-edus.png"),
  // logoImageWhite: require("../src/img/logo-edus-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // LBS wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.lbstoken.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-lbs.png"),
  // logoImageWhite: require("../src/img/logo-lbs-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // KLAVIS wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.klaviswallet.co.kr",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-klavis.png"),
  // logoImageWhite: require("../src/img/logo-klavis-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false

  // ICT wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.icointoo.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-ict.png"),
  // logoImageWhite: require("../src/img/logo-ict-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: true // 거래현황에서 LOCK확인

  // LEMC wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.lemcwallet.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-lemc.png"),
  // logoImageWhite: require("../src/img/logo-lemc-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: true // 거래현황에서 LOCK확인

  // LEMC external wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "api.lemcwallet.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-lemc.png"),
  // logoImageWhite: require("../src/img/logo-lemc-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: false // 거래현황에서 LOCK확인

  // LEMC external dev wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "dev.lemcwallet.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-lemc.png"),
  // logoImageWhite: require("../src/img/logo-lemc-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: false // 거래현황에서 LOCK확인

  // penton wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.pentonwallet.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-penton.png"),
  // logoImageWhite: require("../src/img/logo-penton-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: false, // 거래현황에서 LOCK확인
  // mainCoinType: 301

  // wive token wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.wivetoken.io",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: true, // 로고 이미지
  // logoImage: require("../src/img/logo-wive.png"),
  // logoImageWhite: require("../src/img/logo-wive-white.png"),
  // isOrganizationFlag: false,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: false, // 거래현황에서 LOCK확인
  // mainCoinType: 301

  // utop wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.utop-wallet.com",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지
  // logoImage: require("../src/img/logo-wive.png"),
  // logoImageWhite: require("../src/img/logo-wive-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: false,
  // isDecentralFlag: false, // 거래현황에서 LOCK확인
  // mainCoinType: 201

  // DTP wallet
  // isFoundationInFee: false,
  // isPurchaseInFee: false,
  // serverIp: "back.digitaltrustuniverse.io",
  // // naver map client key
  // clientId: "r06yvi25vl",
  // clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  // isLogoFlag: false, // 로고 이미지
  // logoImage: require("../src/img/logo-wive.png"),
  // logoImageWhite: require("../src/img/logo-wive-white.png"),
  // isOrganizationFlag: true,
  // isP2PFlag: false,
  // isPreviewFlag: true,
  // isDecentralFlag: false, // 거래현황에서 LOCK확인
  // mainCoinType: 201

  // DRNT wallet
  isFoundationInFee: false,
  isPurchaseInFee: false,
  serverIp: "back.drnt-wallet.com",
  // naver map client key
  clientId: "r06yvi25vl",
  clientSecret: "zxbz0455mCXQdp8u4aPzDRhUZkSajLNThQH3ZkbZ",
  isLogoFlag: true, // 로고 이미지
  logoImage: require("../src/img/logo-drnt.png"),
  logoImageWhite: require("../src/img/logo-drnt-white.png"),
  isOrganizationFlag: false,
  isP2PFlag: false,
  isPreviewFlag: false,
  isDecentralFlag: false // 거래현황에서 LOCK확인

}
