import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en", "ja", "zh"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        // if (
        //   url === serverUrl + httpUrl.login ||
        //   url === serverUrl + httpUrl.logout
        // ) {
        // } else {
        //   if (
        //     (method === 'PUT' || method === 'POST' || method === 'DELETE') &&
        //     response.data.result === 'SUCCESS'
        //   ) {
        //     alert('완료');
        //   }
        // }
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove("adminUser");
          global.location.href = "/";
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.get(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.put(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
  // return new Promise((resolve, reject) => {
  //   Axios.post(makeUrl(url, params), data)
  //     .then(response => {
  //       resolve(response.data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      responseType: "arraybuffer"
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",

  updateUserBalance: "/wallet/balance/refresh/%s/%s",
  updateBalance: "/wallet/balance/refresh/decent/%s/%s",

  //blockchain coin decimal
  getCoinDecimal: "/blockchain/list/decimal?language=%s",
  getSearchSerial: "/foundation/search?coinType=%s&address=%s",

  //member
  userList:
    "/user/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s&searchType=%s&startDate=%s&endDate=%s",
  userUpdate: "/user/update",
  userDelete: "/user/delete/%s",
  walletList: "/user/walletList?pageSize=%s&pageNum=%s&serial=%s",
  walletListByIdx: "/user/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  walletLogList:
    "/user/walletLogList?pageSize=%s&pageNum=%s&serial=%s&type=%s&searchText=%s&transferType=%s&coinType=%s",
  coinLockList:
    "/user/coinLockList?pageSize=%s&pageNum=%s&serial=%s&coinType=%s&deleted=%s&expire=%s",
  coinLockUpdate: "/user/coinLockUpdate",
  coinLockBalance: "/user/coinLockBalance?serialNum=%s&coinType=%s",
  coinLockDelete: "/user/lockDelete",
  userListDownload:
    "/user/list/excel?pageSize=%s&pageNum=%s&searchText=%s&deleted=%s&searchType=%s",
  userExcelDownload:
    "/user/list/user/excel?pageSize=%s&pageNum=%s&searchText=%s&deleted=%s&searchType=%s&startDate=%s&endDate=%s",
  getUserToken: "/user/get/token/%s",
  changePassword: "/user/change/password",
  getP2PLogList:
    "/user/p2pLogList?pageSize=%s&pageNum=%s&logIdx=%s&deleted=%s&expire=%s",

  //wallet
  feeWalletList: "/wallet/feeWallet/list?type=%s&deleted=%s&searchText=%s",
  feeWalletCreate: "/wallet/feeWallet/create",
  feewWalletUpdate: "/wallet/feeWallet/update",
  feeWalletDelete: "/wallet/feeWallet/delete/%s",
  collectionList: "/wallet/fee/list?pageSize=%s&pageNum=%s",
  collectionUpdate: "/wallet/fee/update",
  feeLogList:
    "/wallet/feeLogList?pageSize=%s&pageNum=%s&searchText=%s&type=%s&coinType=%s&startDate=%s&endDate=%s",
  downLoadFeeLogList:
    "/wallet/feeLogList/excel?pageSize=%s&pageNum=%s&searchText=%s&type=%s&coinType=%s&startDate=%s&endDate=%s",
  cautionList:
    "/wallet/cautionWallet/list?pageSize=%s&pageNum=%s&cautionType=%s&searchText=%s",
  cautionWalletCreate: "/wallet/cautionWallet/create",
  cautionWalletDelete: "/wallet/cautionWallet/delete/%s/%s/%s",
  foundationList: "/wallet/foundation/list?pageSize=%s&pageNum=%s",
  foundationCreate: "/wallet/foundation/create",
  foundationUpdate: "/wallet/foundation/update",
  foundationDelete: "/wallet/foundation/delete/%s",
  getMergeList: "/wallet/merge/list?pageSize=%s&pageNum=%s&coinType=%s&fee=%s",
  walletMerge: "/wallet/merge",
  getMergeLogList:
    "/wallet/merge/list/mergeLog?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  getEthLogList:
    "/wallet/merge/list/ethFeeLog?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  downLoadEthLogList:
    "/wallet/merge/list/ethFeeLog/excel?pageSize=%s&pageNum=%s&logType=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  getP2PCautionList:
    "/wallet/p2pCautionWallet/list?pageSize=%s&pageNum=%s&searchText=%s",
  p2pCautionCreate: "/wallet/p2pCautionWallet/create",
  p2pCautionCreateExcel: "/wallet/p2pCautionWallet/excelUpload",
  p2pCautionUpdate: "/wallet/p2pCautionWallet/update",
  p2pCautionDelete: "/wallet/p2pCautionWallet/delete",
  getCoinBalance: "/wallet/coin/balance",

  //transaction
  setList: "/transaction/approvalSetting/list?pageSize=%s&pageNum=%s",
  setUpdate: "/transaction/approvalSetting/update",
  processList:
    "/transaction/approvalStandby/list?pageSize=%s&pageNum=%s&type=%s&reasonType=%s&searchText=%s",
  processUpdate: "/transaction/approvalStandby/update",
  getlimitAmount: "/transaction/limitAmount/list",
  limitUpdate: "/transaction/limitAmount/update",
  limitDelete: "/transaction/limitAmount/delete/%s/%s/%s",
  getTransferRequestList:
    "/transaction/transferRequestList?pageSize=%s&pageNum=%s",
  getTransferLog:
    "/transaction/transferLogList?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%s&transferType=%s",
  downloadTransferLogExcel:
    "/transaction/transferLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%&transferType=%s",
  downloadTokenTransferLogExcel:
    "/transaction/transferLogList/excel/token?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%&transferType=%s",
  getTransferP2PLogList:
    "/transaction/p2pLogList?pageSize=%s&pageNum=%s&logIdx=%s&deleted=%s&expire=%s",
  getTransferP2PLogListExcel:
    "/transaction/p2pLogList/excel?pageSize=%s&pageNum=%s&logIdx=%s&deleted=%s&expire=%s",

  //coin
  distributeList:
    "/coin/airDropLogList?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  tokenPaymentList:
    "/coin/airDropLogList?pageSize=%s&pageNum=%s&type=%s&searchText=%s&startDate=%s&endDate=%s",
  tokenPaymentListExcel:
    "/coin/airDropLogList/token/excel?pageSize=%s&pageNum=%s&type=%s&searchText=%s&startDate=%s&endDate=%s",
  manualCreate: "/coin/manual/excel",
  manualSingleCreate: "/coin/manual/single",
  coinAutoList: "/coin/auto/list?pageSize=%s&pageNum=%s",
  autoUpdate: "/coin/auto/update",

  getLockList:
    "/foundation/decentral/get/lock?coinType=%s&searchType=%s&searchText=%s",
  lockSet: "/foundation/decentral/set/lock",
  lockSetExcel: "/foundation/decentral/set/lock/excelUpload",
  unLock: "/foundation/decentral/unLock",
  getCoinUserList: "/foundation/decentral/search/user?coinType=%s&id=%s",
  getLockRequestList:
    "/foundation/decentral/list/lockRequest?pageSize=%s&pageNum=%s&lockType=%s&statusType=%s&searchType=%s&searchText=%s",
  getDailyLockList:
    "/foundation/decentral/get/daily/lock?coinType=%s&searchType=%s&searchText=%s",
  getDailyLockRequestList:
    "/foundation/decentral/list/dailyLockRequest?pageSize=%s&pageNum=%s&lockType=%s&statusType=%s&searchType=%s&searchText=%s",
  dailyLockSet: "/foundation/decentral/set/dailyLock",
  dailyLockSetExcel: "/foundation/decentral/set/dailyLock/excelUpload",
  unDailyLock: "/foundation/decentral/undailyLock",

  //community
  noticeList:
    "/community/notice/list?pageSize=%s&pageNum=%s&deleted=%s&category=%s&searchText=%s",
  noticeCreate: "/community/notice/create",
  noticeUpdate: "/community/notice/update",
  noticeDelete: "/community/notice/delete/%s",
  faqList:
    "/community/faq/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  faqCreate: "/community/faq/create",
  faqUpdate: "/community/faq/update",
  faqDelete: "/community/faq/delete/%s",
  getLinkList: "/community/link/list?pageSize=%s&pageNum=%s&language=%s",
  linkCreate: "/community/link/create",
  linkUpdate: "/community/link/update",
  linkDelete: "/community/link/delete/%s",
  linkRankUpdate: "/community/link/update/rank",
  qnaList:
    "/community/qna/list?pageSize=%s&pageNum=%s&categoryIdx=%s&status=%s",
  qnaUpdate: "/community/qna/update",
  qnaCategoryList: "/community/qnaCategory/list?category=%s",
  qnaCategoryUpdate: "/community/qnaCategory/update",
  qnaCategoryDelete: "/community/qnaCategory/delete/%s",

  //statistics
  userStatisticsList:
    "/statistics/user/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  userSignStatisticsList:
    "/statistics/user/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&periodType=%s",
  userSignStatisticsListExcel:
    "/statistics/user/list/excel?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&periodType=%s",
  loginStatisticsList:
    "/statistics/login/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  transferStatisticsList:
    "/statistics/transfer/list?pageSize=%s&pageNum=%s&type=%s&startDate=%s&endDate=%s&p2pFlag=%s",
  tokenTransferStatisticsList:
    "/statistics/transfer/token/list?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s&coinType=%s",
  tokenTransferStatisticsListExcel:
    "/statistics/transfer/token/list/excel?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s&coinType=%s",
  virtualAccountStatisticsList:
    "/statistics/virtualAccount/list?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s&organizationIdx=%s",
  virtualAccountStatisticsListExcel:
    "/statistics/virtualAccount/list/excel?pageSize=%s&pageNum=%s&periodType=%s&startDate=%s&endDate=%s&organizationIdx=%s",

  getFeeDateStatistic:
    "/statistics/feeDate/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getFeeMonthStatistic:
    "/statistics/feeMonth/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",
  getAdminLoginLog:
    "/statistics/adminLoginLog/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s",

  //foundation
  getFoundationList: "/foundation/list",
  getFoundationCount: "/foundation/list/user/count?coinType=%s",
  foundationUserList:
    "/foundation/list/user?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  foundationListExcel:
    "/foundation/list/user/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  getTokenState: "/foundation/decentral/token/state",
  tokenHolingStatusExcel:
    "/foundation/list/user/excel/token?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  decentralFoundationListExcel:
    "/foundation/list/user/decentral/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&searchText=%s",
  foundationUserLockList:
    "/foundation/list/user/lock?pageSize=%s&pageNum=%s&coinType=%s&address=%s",
  foundationUserLockDetail:
    "/foundation/list/user/lockDetail?pageSize=%s&pageNum=%s&coinType=%s&address=%s",
  foundationLockList:
    "/foundation/lockList?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s",
  foundationLockExcel:
    "/foundation/lockList/excel?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&orderType=%s&orderByType=%s",
  foundationLockCreateExcel: "/foundation/lock/excelUpload",
  lockUpdate: "/foundation/lock/update",
  lockDelete: "/foundation/lock/delete",
  lockMuptiDelete: "/foundation/lockMultipleDelete",
  foundationSend: "/foundation/send/fee",
  foundationSendNoFee: "/foundation/send",
  foundationExcel: "/foundation/send/excel/fee",
  foundationExcelNoFee: "/foundation/send/excel",
  foundationCoinLog:
    "/foundation/logList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&requestType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationCoinLogExcel:
    "/foundation/logList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&requestType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationCoinLogLock:
    "/foundation/logList/lock?pageSize=%s&pageNum=%s&idx=%s",
  designationList:
    "/foundation/lockSelectLogList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&dateType=%s&deleted=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s&expireDate=%s",
  designationListExcel:
    "/foundation/lockSelectLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&dateType=%s&deleted=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s",
  designationStatus:
    "/foundation/lockSelectList?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s&expireDate=%s",
  designationStatusExcel:
    "/foundation/lockSelectList/excel?pageSize=%s&pageNum=%s&coinType=%s&orderType=%s&orderByType=%s&allLock=%s&startDate=%s&endDate=%s&searchText=%s",
  foundationDaily:
    "/foundation/statBudgetList?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationDailyExcel:
    "/foundation/statBudgetList/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&startDate=%s&endDate=%s",
  foundationDetail:
    "/foundation/budgetLogList?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&selectType=%s&startDate=%s&endDate=%s",
  foundationDetailExcel:
    "/foundation/budgetLogList/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&orderType=%s&orderByType=%s&selectType=%s&startDate=%s&endDate=%s",

  //system
  adminList: "/system/admin/list?pageSize=%s&pageNum=%s&searchText=%s",
  adminCreate: "/system/admin/create",
  adminUpdate: "/system/admin/update",
  adminDelete: "/system/admin/delete/%s",
  coinAuthList: "/system/admin/authCoinDetail/%s",
  authList: "/system/admin/authDetail/%s",
  authUpdate: "/system/admin/authUpdate",
  authCoinUpdate: "/system/admin/authCoinUpdate",
  serviceList: "/system/serviceStatus/list",
  serviceCreate: "/system/serviceStatus/create",
  serviceUpdate: "/system/serviceStatus/update",
  serviceDelete: "/system/serviceStatus/delete/%s",
  blockChainList: "/system/blockchain/list?searchText=%s",
  blockChainDetail: "/system/blockchain/detail/%s",
  blockChainCreate: "/system/blockchain/create",
  blockChainUpdate: "/system/blockchain/update",
  blockChainDelete: "/system/blockchain/delete/%s",
  blockChainRankUpdate: "/system/blockchain/update/rank",
  getCustomConfigList: "/system/customConfig/list",
  customConfigUpdate: "/system/customConfig/update",

  //franchise
  getFranchiseList:
    "/franchise/franchise/list?pageSize=%s&pageNum=%s&statusType=%s&searchText=%s",
  franchiseCreate: "/franchise/franchise/create",
  franchiseUpdate: "/franchise/franchise/update",
  franchiseDelete: "/franchise/franchise/delete/%s",
  franchiseStatusUpdate: "/franchise/franchise/update/status/%s&%s",
  franchiseCoinType: "/franchise/coinType?language=%s",
  getPurchaseLog:
    "/franchise/purchaseLog?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&coinType=%s",
  getPaybackLog:
    "/franchise/paybackLog?pageSize=%s&pageNum=%s&coinType=%s&searchType=%s&searchText=%s",
  getUserDetail: "/franchise/purchaseLog/user/%s",
  getFranchiseDetail: "/franchise/purchaseLog/franchise/%s",
  getPaybackSetting: "/franchise/paybackSetting/list",
  paybackUpdate: "/franchise/paybackSetting/update",
  paybackDelete: "/franchise/paybackSetting/delete/%s",

  //trade
  getTradeLog:
    "/trade/logList?pageSize=%s&pageNum=%s&tradeType=%s&searchText=%s&coinType=%s",
  getTradeRatio: "/trade/ratio",
  tradeRatioCreate: "/trade/ratio/insert",
  tradeRatioUpdate: "/trade/ratio/update",
  tradeRatioDelete: "/trade/ratio/delete",
  getTradeList: "/trade/approval/logList?pageSize=%s&pageNum=%s&status=%s",
  tradeUpdate: "/trade/approval/logList/update/status",

  // adjustment
  getAdjustmentLogList:
    "/adjustment/list?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&organizationIdx=%s&coinType=%s&orderByType=%s",
  updateAdjustment: "/adjustment/list/statusUpdate",
  getAdjustmentOrganization:
    "/adjustment/list/organization?pageSize=%s&pageNum=%s&deleted=%s&franchiseType=%s&searchText=%s",
  getAdjustmentShareList:
    "/adjustment/share/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&organizationIdx=%s&status=%s&orderByType=%s",
  updateAdjustmentShare: "/adjustment/share/statusUpdate",
  getAdjustmentShareDetail:
    "/adjustment/share/detail?pageSize=%s&pageNum=%s&logDate=%s&organizationIdx=%s",
  downloadAdjustmentLog:
    "/adjustment/list/excel?pageSize=%s&pageNum=%s&adjustmentStatus=%s&startDate=%s&endDate=%s&organizationIdx=%s&coinType=%s&orderByType=%s",
  downloadAdjustmentShare:
    "/adjustment/share/list/excel?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&organizationIdx=%s&status=%s&orderByType=%s",

  // payment
  getOrganiztionList:
    "/payment/organization/list?pageSize=%s&pageNum=%s&deleted=%s&franchiseType=%s&searchText=%s",
  getLowerOrganizationList: "/payment/organization/list/lowerOrg/%s",
  organizationCreate: "/payment/organization/create",
  organizationUtopCreate: "/payment/organization/utop/create",
  getUserList:
    "/payment/user/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  getUserRecommender: "/payment/user/recommend/%s",
  getRecommenderList:
    "/payment/recommender/list?pageSize=%s&pageNum=%s&idx=%s&searchText=%s",
  organizationUpdate: "/payment/organization/update",
  organizationDelete: "/payment/organization/delete/%s",
  getOrganizationCategoryList: "/payment/organization/category/%s",
  organizationCategoryCreate: "/payment/organization/category/create",
  organizationCategoryDelete: "/payment/organization/category/delete",
  getPaymentSettingList: "/payment/paymentSetting/list/%s",
  paymentSettingUpdate: "/payment/paymentSetting/update",
  paymentSettingDelete: "/payment/paymentSetting/delete/%s/%s",
  getPaymentLogList:
    "/payment/paymentLog/list?pageSize=%s&pageNum=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  getPaymentLogExcel:
    "/payment/paymentLog/list/excel?pageSize=%s&pageNum=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  tokenPaymentLogExcel:
    "/payment/paymentLog/list/excel/token?pageSize=%s&pageNum=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s",
  getPartnerPaymentLogList:
    "/payment/paymentLog/list?pageSize=%s&pageNum=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s&statusType=%s&cancelStatusType=%s",
  paymentCancel: "/payment/paymentLog/cancel/%s",
  partnerPaymentLogExcel:
    "/payment/paymentLog/list/excel/partner?pageSize=%s&pageNum=%s&organizationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s&searchText=%s&statusType=%s&cancelStatusType=%s",
  getAdjustmentSetting: "/payment/adjustmentSetting",
  updateAdjustmentSetting: "/payment/adjustmentSetting/update",
  getCategoryList: "/payment/category/list?idx=%s&searchText=%s",
  categoryCreate: "/payment/category/create",
  categoryUpdate: "/payment/category/update",
  categoryDelete: "/payment/category/delete/%s",
  getFranchiseQr: "/payment/franchise/list?pageSize=%s&pageNum=%s",
  getFoundationSelectList: "/payment/foundation/list",
  getFoundationWalletList: "/payment/foundation/wallet/list?foundationIdx=%s",
  getFoundationWalletChargeList:
    "/payment/foundation/chargeLog?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s",
  getFoundationWalletChargeExcel:
    "/payment/foundation/chargeLog/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&orderByType=%s&startDate=%s&endDate=%s",
  paymentFoundationCharge: "/payment/foundation/wallet/charge",
  paymentFoundationCollection: "/payment/foundation/wallet/collection",

  // banner
  getBannerList: "/banner/list?deleted=%s&orderByType=%s",
  bannerCreate: "/banner/create",
  bannerUpdate: "/banner/update",
  bannerDelete: "/banner/delete/%s",

  // event
  getEventList:
    "/event/list?pageSize=%s&pageNum=%s&deleted=%s&expireDate=%s&orderByType=%s",
  eventCreate: "/event/create",
  eventUpdate: "/event/update",
  eventDelete: "/event/delete/%s",

  // marketing
  getDepositWalletList: "/marketing/deposit/wallet/list",
  depositWalletUpdate: "/marketing/deposit/wallet/update",
  getDepositThirdpartyList:
    "/marketing/deposit/thirdparty/list?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",
  getWithdrawCurrencyList:
    "/marketing/withdrawCurrency/list?pageSize=%s&pageNum=%s",
  withdrawCurrencyUpdate: "/marketing/withdrawCurrency/update",
  getPointChargeLogList:
    "/marketing/pointChargeLog/list?pageSize=%s&pageNum=%s&searchType=%s&searchText=%s&startDate=%s&endDate=%s",
  getTransferLogList:
    "/marketing/transferLog/coin/list?pageSize=%s&pageNum=%s&coinType=%s&searchText=%s&startDate=%s&endDate=%s",

  // external
  getExternalFoundationList: "/external/foundation/list",
  getExternalFoundationBalanceList:
    "/external/foundation/list/balance?pageSize=%s&pageNum=%s&idx=%s",
  getExternalFoundationCoinList: "/external/foundation/list/coin?idx=%s",
  externalFoundationBalanceCharge: "/external/foundation/charge",
  externalFoundationBalanceWithdraw: "/external/foundation/withdraw",
  getExternalFoundationChargeLogList:
    "/external/foundation/list/chargeLog?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&type=%s&startDate=%s&endDate=%s",
  getExternalFoundationChargeLogExcel:
    "/external/foundation/list/chargeLog/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&type=%s&startDate=%s&endDate=%s",
  getExternalTransferLogList:
    "/external/foundation/list/transferLog?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&type=%s&startDate=%s&endDate=%s",
  getExternalTransferLogExcel:
    "/external/foundation/list/transferLog/excel?pageSize=%s&pageNum=%s&foundationIdx=%s&coinType=%s&type=%s&startDate=%s&endDate=%s",

  // urlLink
  getUrlLinkList: "/urlLink/list?pageSize=%s&pageNum=%s&linkType=%s&deleted=%s",
  urlLinkCreate: "/urlLink/create",
  urlLinkUpdate: "/urlLink/update",
  urlLinkDelete: "/urlLink/delete/%s",

  // exchange
  getExchangeList:
    "/exchange/list?pageSize=%s&pageNum=%s&deleted=%s&orderByType=%s",
  exchangeCreate: "/exchange/create",
  exchangeUpdate: "/exchange/update",
  exchangeDelete: "/exchange/delete/%s",

  getExchangeRequestList:
    "/exchangeRequest/list?pageSize=%s&pageNum=%s&statusType=%s&searchType=%s&searchText=%s",
  exchangeRequestUpdate: "/exchangeRequest/update",

  // partner
  paymentStatistics:
    "/statistics/payment/list?pageSize=%s&pageNum=%s&organizationIdx=%s&periodType=%s&status=%s&startDate=%s&endDate=%s",
  paymentCheck: "/statistics/payment/check",
  paymentStatisticsExcel:
    "/statistics/payment/list/excel?pageSize=%s&pageNum=%s&organizationIdx=%s&periodType=%s&status=%s&startDate=%s&endDate=%s",

  // aduit
  getAdministratorAudit:
    "/audit/admin/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  getAdministratorAuditExcel:
    "/audit/admin/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  getUserAudit:
    "/audit/user/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%sauditResultType&auditResultType=%s",
  getUserAuditExcel:
    "/audit/user/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s&auditResultType=%s",
  getVitrualAccountAudit:
    "/audit/virtualAccount/list?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",
  getVitrualAccountAuditExcel:
    "/audit/virtualAccount/list/excel?pageSize=%s&pageNum=%s&searchText=%s&startDate=%s&endDate=%s",

  // popup
  getPopUpList:
    "/popUp/list?pageSize=%s&pageNum=%s&orderByType=%s&deleted=%s&expireDate=%s",
  popUpCreate: "/popUp/create",
  popUpUpdate: "/popUp/update",
  popUpDelete: "/popUp/delete/%s",

  // adminUserInfo
  adminUserUpdate: "/myPage/update",
  otpUpdate: "/myPage/otp/change/key",

  //image
  imageUpload: "/image/upload",
  getImage: "/image/",
  imageEncoding: "/image/imageEncoding",

  //map
  getGeocode: "/map/geocode?address=%s",

  //
  getAddress: "/foundation/send/getAddress?coinType=%s&serial=%s"
};

const imageType = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType
};
