import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, DatePicker, Button, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat, dayFormat, statFormat, statMonthBeforeFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import string from "../../string";

const { RangePicker } = DatePicker;

class UserSignStatistics extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 0
      },
      startDate: statMonthBeforeFormat(date),
      endDate: statFormat(date),
      periodType: "DAY",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getUserStatistics({ pageNum: pager.current });
    }
  };

  // 주기 선택
  handleChangePeriodType = (value) => {
    this.setState({
      periodType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { isLoaded, pagination, startDate, endDate, periodType } = this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.userSignStatisticsListExcel,
          [total, 1, startDate, endDate, periodType],
          {}
        )
          .then((res) => {
            fileDownload(
              res,
              `${startDate} ~ ${endDate} ${string.partnerStatistics[periodType]} 회원가입통계.xlsx`
            );
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 회원 통게 목록 조회
  getUserStatistics = (params = {}) => {
    let pageNum = params.pageNum || 1;
    const { startDate, endDate, periodType } = this.state;

    httpGet(httpUrl.userSignStatisticsList, [10, pageNum, startDate, endDate, periodType], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.userList === null ? [] : res.data.userList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getUserStatistics();
  }

  render() {
    const { periodType, startDate, endDate, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        // key: 'statDate',
        // width: 80,
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "회원가입한 회원",
        dataIndex: "joinUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "탈퇴한 회원",
        dataIndex: "exitUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "총 회원 수",
        dataIndex: "totalUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">회원가입 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-end m-b-10">
          <label className="flex-row flex-center m-r-10">
            주기&nbsp;&nbsp;
            <SelectBox
              style={{ width: "160px" }}
              value={string.partnerStatistics[periodType]}
              codeString={string.partnerStatistics}
              onChange={this.handleChangePeriodType}
            />
          </label>
          <label className="flex-row flex-center m-r-10">
            기간&nbsp;
            <RangePicker
              locale={locale}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              format={dateFormat}
              allowClear={false}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>
          <Button
            type="primary"
            onClick={() => {
              this.getUserStatistics();
            }}>
            검색
          </Button>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          // rowKey={record => record.statDate}
          columns={statisticsColumns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UserSignStatistics;
