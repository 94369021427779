import React, { Component } from "react";

import { Table, Checkbox, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";

import string from "../../string";
import { numberFormat, formatDate } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import BannerModal2 from "../../components/banner/BannerModal2";
import { showAlert } from "../../components/common/AlertModal";

class BannerList2 extends Component {
  state = {
    list: [],
    deleted: false,
    orderByType: "DESC",

    type: false,
    visible: false,
    selectedRow: {}
  };

  // 삭제 포함 여부 설정
  deleteHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  // 삭제 및 복원
  onDelete = (row) => {
    if (
      global.confirm(
        row.deleted
          ? `위치 ${
              row.position === 1 ? "메인1" : row.position === 2 ? "메인2" : row.position
            } 배너를 복원하시겠습니까?`
          : `위치 ${
              row.position === 1 ? "메인1" : row.position === 2 ? "메인2" : row.position
            } 배너를 삭제하시겠습니까?`
      )
    )
      httpPut(httpUrl.bannerDelete, [row.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            this.getBannerList();
            row.deleted ? showAlert("restore") : showAlert("update");
          } else {
            alert("위치가 중복되었습니다. \n해당 위치의 배너를 삭제 후 다시 실행해주세요.");
          }
        })
        .catch((e) => {});
  };

  // 배너 목록 조회
  getBannerList = () => {
    const { deleted, orderByType } = this.state;

    httpGet(httpUrl.getBannerList, [deleted, orderByType], {})
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getBannerList();
  }

  render() {
    const { type, visible, selectedRow } = this.state;

    const columns = [
      {
        title: "순번",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "위치",
        dataIndex: "position",
        className: "text-center",
        render: (data) => <div>{data === 1 ? "메인1" : data === 2 ? "메인2" : data}</div>
      },
      {
        title: "링크",
        dataIndex: "url",
        // width: 80,
        className: "text-center",
        render: (data, row, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => this.setState({ visible: true, type: true, selectedRow: row })}>
              {data}
            </div>
          );
        }
      },
      {
        title: "시작일",
        dataIndex: "startDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 배너 생성 / 수정 */}
        {visible && (
          <BannerModal2
            type={type}
            visible={visible}
            data={selectedRow}
            onCancel={() => {
              this.setState({ visible: false, type: false, selectedRow: {} });
            }}
            onOk={this.getBannerList}
          />
        )}
        <div className="title">배너관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, type: false });
            }}>
            생성
          </Button>
          <div>
            검색조건&nbsp;&nbsp;&nbsp;
            <Checkbox onChange={this.deleteHandleChange}>삭제 포함</Checkbox>
            <Button
              onClick={() => {
                this.getBannerList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.list}
          style={{ marginBottom: "10px" }}
          pagination={false}
        />
      </div>
    );
  }
}

export default BannerList2;
