import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { Table, Button, DatePicker, Select, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";

import {
  numberFormat,
  amountFormat,
  statFormat,
  dayFormat,
  checkData,
  checkCoinName
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";
import OrganizationSelectModal from "../../components/adjustment/OrganizationSelectModal";

const Option = Select.Option;
const { RangePicker } = DatePicker;
class AdjustmentLog extends Component {
  state = {
    isLoaded: false,

    coinList: [],
    adjustmentLogList: [],
    pagination: {
      current: 1,
      total: 0
    },
    organizationIdx: 0,
    organizationName: "",
    adjustmentType: "ALL",
    coinType: 0,
    startDate: "",
    endDate: "",
    orderByType: "DESC",

    isMemoModify: false,
    isStatusComplete: false,
    selectedData: {},

    modalVisible: false,
    inputModalVisible: false,
    modifyData: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getAdjustmentLogList({ pageNum: pager.current });
    }
  };

  // 정산 상태 선택
  handleChangeAdjustmentType = (value) => {
    this.setState({
      adjustmentType: value,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  handleChangeOrderByType = (value) => {
    this.setState({
      orderByType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 지급 여부
  onUpdateAdjustment = (params = {}) => {
    let updateData = {
      idx: params.idx,
      adjustmentStatus: params.adjustmentStatus,
      memo: params.memo,
      receiptPrice: params.receiptPrice
    };
    if (global.confirm("수정하시겠습니까?")) {
      httpPut(httpUrl.updateAdjustment, [], updateData)
        .then((res) => {
          this.getAdjustmentLogList({
            pageNum: this.state.pagination.current
          });
          showAlert("update");
          this.setState({
            inputModalVisible: false
          });
        })
        .catch((e) => {});
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      pagination,
      adjustmentType,
      startDate,
      endDate,
      coinType,
      orderByType,
      organizationIdx,
      organizationName,
      isLoaded
    } = this.state;

    let result = [];
    let title = "정산 내역";

    if (startDate !== "" && endDate !== "") {
      result.push(`${startDate} ~ ${endDate} `);
    }

    if (organizationIdx !== 0) {
      result.push(`${organizationName} `);
    }

    if (adjustmentType !== "ALL") {
      result.push(`${string.adjustmentString[adjustmentType]} `);
    }

    let total = pagination.total <= 9 ? 10 : pagination.total;

    title =
      result.reduce((acc, item, index) => {
        return acc + item;
      }, "") + title;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(httpUrl.downloadAdjustmentLog, [
          total,
          1,
          adjustmentType,
          startDate,
          endDate,
          organizationIdx,
          coinType,
          orderByType
        ])
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 정산내역 조회
  getAdjustmentLogList = (params = {}) => {
    const {
      adjustmentType,
      startDate,
      endDate,
      coinType,
      orderByType,
      organizationIdx
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getAdjustmentLogList,
      [
        10,
        pageNum,
        adjustmentType,
        startDate,
        endDate,
        organizationIdx,
        coinType,
        orderByType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          adjustmentLogList: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");

    this.setState(
      {
        coinList: [
          {
            coinType: 0,
            name: "전체"
          },
          ...coinList
        ]
      },
      () => {
        this.getAdjustmentLogList();
      }
    );
  }

  render() {
    const { coinList } = this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "조직 이름",
        dataIndex: "organizationName",
        className: "text-center"
      },
      {
        title: "일자",
        dataIndex: "logDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "은행",
        dataIndex: "bankCode",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === null || data === "" ? "-" : string.bankString[data]}
          </div>
        )
      },
      {
        title: "계좌번호",
        dataIndex: "bankAccount",
        className: "text-center",
        render: (data, row) => <div>{checkData(data)}</div>
      },
      {
        title: "예금주",
        dataIndex: "accountHolder",
        className: "text-center",
        render: (data, row) => <div>{checkData(data)}</div>
      },
      {
        title: "정산 금액",
        dataIndex: "price",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "실 지급금액",
        dataIndex: "receiptPrice",
        className: "text-center",
        render: (data, row) => <div>{numberFormat(data)}</div>
      },
      {
        title: "정산수수료 비율",
        dataIndex: "feeRatio",
        className: "text-center",
        render: (data, row) => (
          <div>{data === null ? "-" : `${numberFormat(data)} %`}</div>
        )
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => <div>{string.adjustmentString[data]}</div>
      },
      {
        title: "지급여부",
        dataIndex: "modify",
        className: "text-center",
        render: (data, row) =>
          row.status === "CREATED" ? (
            <div className="flex-row flex-center justify-center">
              <Button
                className="m-r-10"
                onClick={() => {
                  this.setState({
                    inputModalVisible: true,
                    modifyData: "price",
                    selectedData: row
                  });
                }}
              >
                완료
              </Button>
              <Button
                onClick={() => {
                  this.onUpdateAdjustment({
                    idx: row.idx,
                    adjustmentStatus: "REJECT",
                    memo: row.memo,
                    receiptPrice: row.receiptPrice
                  });
                }}
              >
                거절
              </Button>
            </div>
          ) : (
            <div>-</div>
          )
      }
    ];

    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          key: "coinType",
          title: "코인 종류",
          dataIndex: "coinType",
          className: "text-center",
          render: (data) => <div>{checkCoinName(data)}</div>
        },
        {
          title: "코인 수량",
          dataIndex: "amount",
          className: "text-center",
          render: (data, row) => (
            <div>{data ? amountFormat(data, row.coinType) : null}</div>
          )
        },
        {
          title: "코인 단가",
          dataIndex: "unitPrice",
          className: "text-center",
          render: (data, row) => <div>{data ? numberFormat(data) : "-"}</div>
        },
        {
          title: "메모",
          dataIndex: "memo",
          className: "text-center",
          render: (data, row) => (
            <div
              className="flex-row flex-center justify-center"
              style={{ wordBreak: "keep-all" }}
            >
              {checkData(data)}
            </div>
          )
        },
        {
          className: "text-center",
          render: (data, row) => (
            <Button
              onClick={() => {
                this.setState({
                  inputModalVisible: true,
                  modifyData: "memo",
                  selectedData: row
                });
              }}
            >
              메모 수정
            </Button>
          )
        }
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">정산 내역</div>
        {/* 조직 선택 */}
        {this.state.modalVisible && (
          <OrganizationSelectModal
            visible={this.state.modalVisible}
            onOk={(idx, name) => {
              this.setState({
                organizationIdx: idx,
                organizationName: name,
                isLoaded: true
              });
            }}
            onCancel={() => {
              this.setState({
                modalVisible: false
              });
            }}
          />
        )}
        {/* 지급 여부 선택 */}
        {this.state.inputModalVisible && (
          <ConfirmInputModal
            rootPage="adjustment"
            modifyData={this.state.modifyData}
            visible={this.state.inputModalVisible}
            onOk={this.onUpdateAdjustment}
            onCancel={() => {
              this.setState({
                inputModalVisible: false
              });
            }}
            selectedRow={this.state.selectedData}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  modalVisible: true
                });
              }}
            >
              조직 선택
            </Button>
            {this.state.organizationIdx !== 0 && (
              <div>
                {`가맹점: ${this.state.organizationName}`}
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      organizationIdx: 0
                    });
                  }}
                >
                  <Icon type="close" />
                </Button>
              </div>
            )}
          </div>
          <div className="flex-row flex-center">
            <label>검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <div className="m-r-10" />
            <Button
              type="primary"
              onClick={() => {
                this.getAdjustmentLogList();
              }}
            >
              조회
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <div className="flex-row flex-center m-r-10">
              <label>코인 종류&nbsp;</label>
              <Select
                placeholder="코인종류"
                style={{ width: "160px", marginRight: "10px" }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}
              >
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="flex-row flex-center m-r-10">
              <label>정산 상태&nbsp;</label>
              <SelectBox
                value={string.adjustmentString[this.state.adjustmentType]}
                codeString={string.adjustmentString}
                onChange={(value) => {
                  if (value !== this.state.adjustmentType) {
                    this.handleChangeAdjustmentType(value);
                  }
                }}
                style={{ width: "160px" }}
              />
            </div>
            <div className="flex-row flex-center">
              <label>순서&nbsp;</label>
              <SelectBox
                value={string.orderByString[this.state.orderByType]}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== this.state.orderByType)
                    this.handleChangeOrderByType(value);
                }}
                style={{ width: "160px" }}
              />
            </div>
          </div>
          <Button
            onClick={this.downloadExcel}
            disabled={this.state.adjustmentLogList.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.adjustmentLogList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          columns={columns}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}
export default AdjustmentLog;
