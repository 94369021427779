import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Button, DatePicker, Icon, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";
import {
  checkCoinName,
  checkData,
  coinDivideFormat,
  endDateFormat,
  formatDate,
  startDateFormat
} from "../../util";
import string from "../../string";
import ExternalFoundationSelectBox from "../../components/external/ExternalFoundationSelectBox";
import ExternalFoundationCoinSelectBox from "../../components/external/ExternalFoundationCoinSelectBox";
import SelectBox from "../../components/common/SelectBox";

const { RangePicker } = DatePicker;
class FoundationChargeLog extends Component {
  state = {
    foundationList: [],
    foundationCoinList: [],

    list: [],
    pagination: {
      current: 0,
      total: 0
    },
    foundationIdx: 0,
    coinType: 0,
    type: "ALL",
    startDate: "",
    endDate: "",

    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getExternalFoundationChargeLogList({ pageNum: pager.current });
    }
  };

  // 재단 선택
  handleChageFoundaion = (value) => {
    this.setState({
      foundationIdx: value,
      isLoaded: true
    });
    this.getExternalFoundationCoinList({ idx: value });
  };

  // 재단 코인 선택
  handleChageFoundaionCoin = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 종류 선택
  handleChangeType = (value) => {
    this.setState({
      type: value,
      isLoaded: true
    });
  };

  // 날짜 변경
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      isLoaded,
      pagination,
      foundationList,
      foundationIdx,
      coinType,
      type,
      startDate,
      endDate
    } = this.state;

    if (isLoaded) {
      alert("검색 후 가능합니다.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;
      let name = foundationList.find((list) => list.idx === foundationIdx).siteInfo;
      let date = startDate !== "" && endDate !== "" ? `${startDate} ~ ${endDate} ` : "";

      let title = `${date}${name} ${checkCoinName(coinType)} 재단 충전내역`;
      // console.log(`title = ${title}`);

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.getExternalFoundationChargeLogExcel,
          [total, 1, foundationIdx, coinType, type, startDate, endDate],
          {}
        )
          .then((res) => {
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((e) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 재단 충전내역 조회
  getExternalFoundationChargeLogList = (params = {}) => {
    const { foundationIdx, type, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;
    let coinType = params.coinType || this.state.coinType;

    if (foundationIdx === 0) alert("재단을 선택해주세요");
    if (foundationIdx !== 0 && coinType === 0) alert("코인 종류를 선택해주세요");
    if (foundationIdx !== 0 && coinType !== 0)
      httpGet(
        httpUrl.getExternalFoundationChargeLogList,
        [10, pageNum, foundationIdx, coinType, type, startDate, endDate],
        {}
      )
        .then((res) => {
          // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            list: res.data.list === null ? [] : res.data.list,
            pagination
          });
        })
        .catch((e) => {});
  };

  // 재단 코인 목록
  getExternalFoundationCoinList = (params = {}) => {
    let idx = params.idx || this.state.foundationIdx;

    httpGet(httpUrl.getExternalFoundationCoinList, [idx], {})
      .then((res) => {
        this.setState({
          foundationCoinList: res.data,
          coinType: res.data[0].coinType
        });
        this.getExternalFoundationChargeLogList({
          coinType: res.data[0].coinType
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    // 재단 목록
    httpGet(httpUrl.getExternalFoundationList, [], {})
      .then((res) => {
        // console.log(`foundationList = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          foundationList: res.data,
          foundationIdx: res.data[0].idx
        });
        this.getExternalFoundationCoinList({ idx: res.data[0].idx });
      })
      .catch((e) => {});
  }

  render() {
    const { foundationIdx, foundationList, foundationCoinList, coinType, list, pagination } =
      this.state;
    const columns = [
      {
        width: 300,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      // {
      //   title: "코인 종류",
      //   dataIndex: "coinType",
      //   className: "text-center",
      //   render: (data) => <div>{checkCoinName(data)}</div>
      // },
      {
        title: "종류",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.externalChargeLogString[data]}</div>
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormat(data, row.coinType)}</div>
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      }
    ];

    return (
      <div>
        <div className="title">재단 충전내역</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>
            <label className="m-r-10">
              재단
              <ExternalFoundationSelectBox
                style={{ width: "200px", marginLeft: "10px" }}
                value={foundationIdx === 0 ? "선택해주세요" : foundationIdx}
                foundationList={foundationList}
                onChange={this.handleChageFoundaion}
              />
            </label>
            {foundationCoinList.length !== 0 && (
              <label>
                코인
                <ExternalFoundationCoinSelectBox
                  style={{ width: "200px", marginLeft: "10px" }}
                  value={coinType === 0 ? "선택해주세요" : coinType}
                  foundationCoinList={foundationCoinList}
                  onChange={this.handleChageFoundaionCoin}
                />
              </label>
            )}
          </div>
          <div>
            <label>
              종류
              <SelectBox
                style={{ width: "160px", marginLeft: "10px" }}
                placeholder="선택해주세요"
                value={this.state.type}
                code={string.externalChargeLog}
                codeString={string.externalChargeLogString}
                onChange={this.handleChangeType}
              />
            </label>
            <label className="m-l-10">
              검색기간
              <RangePicker
                style={{ marginLeft: "10px" }}
                locale={locale}
                // placeholder="시작일"
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>
            <Button
              className="m-l-10"
              onClick={() => {
                this.getExternalFoundationChargeLogList();
              }}>
              검색
            </Button>
          </div>
        </div>
        <Button
          className="m-b-10"
          style={{ float: "right" }}
          onClick={this.downloadExcel}
          disabled={list.length === 0 ? true : false}>
          <Icon type="download" style={{ fontSize: "20px" }} />
          엑셀 다운로드
        </Button>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationChargeLog;
