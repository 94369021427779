import React, { Component } from "react";

import { Table, Button } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { formatDate, checkCoinName, coinDivideFormat } from "../../util";
import ExternalFoundationSelectBox from "../../components/external/ExternalFoundationSelectBox";
import FoundationChargeModal from "../../components/external/FoundationChargeModal";

class FoundationBalance extends Component {
  state = {
    foundationList: [],

    list: [],
    pagination: {
      current: 0,
      totatl: 0
    },
    foundationIdx: 0,
    isLoaded: false,

    visibleType: "charge",
    chargeVisible: false,
    selectedData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getExternalFoundationBalanceList({ pageNum: pager.current });
    }
  };

  // 재단 선택
  handelChageFoundaion = (value) => {
    this.setState({
      foundationIdx: value,
      isLoaded: true
    });
  };

  // 재단 잔액 조회
  getExternalFoundationBalanceList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    let foundationIdx = params.foundationIdx || this.state.foundationIdx;

    if (params.refresh) pageNum = this.state.pagination.current;

    if (foundationIdx === 0) alert("재단을 선택해주세요");
    if (foundationIdx !== 0)
      httpGet(httpUrl.getExternalFoundationBalanceList, [10, pageNum, foundationIdx], {})
        .then((res) => {
          // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            list: res.data.list === null ? [] : res.data.list,
            pagination,
            isLoaded: false
          });
        })
        .catch((e) => {});
  };

  componentDidMount() {
    // 재단 목록
    httpGet(httpUrl.getExternalFoundationList, [], {})
      .then((res) => {
        // console.log(`foundationList = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          foundationList: res.data,
          foundationIdx: res.data[0].idx
        });
        this.getExternalFoundationBalanceList({ idx: res.data[0].idx });
      })
      .catch((e) => {});
  }

  render() {
    const { foundationIdx, isLoaded, visibleType, chargeVisible, selectedData, list, pagination } =
      this.state;
    const columns = [
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormat(data, row.coinType)}</div>
      },
      {
        title: "",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                visibleType: "withdraw",
                chargeVisible: true,
                selectedData: row
              });
            }}>
            회수
          </Button>
        )
      }
    ];

    return (
      <div>
        <div className="title">재단 지갑관리</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>
            <label className="m-r-10">재단</label>
            <ExternalFoundationSelectBox
              style={{ width: "200px" }}
              value={foundationIdx === 0 ? "선택해주세요" : foundationIdx}
              foundationList={this.state.foundationList}
              onChange={this.handelChageFoundaion}
            />
            <Button
              className="m-l-10"
              onClick={() => {
                this.getExternalFoundationBalanceList();
              }}>
              검색
            </Button>
          </div>
          <Button
            onClick={() => {
              foundationIdx === 0
                ? alert("재단을 선택해주세요")
                : isLoaded
                ? alert("검색 후 사용가능합니다.")
                : this.setState({
                    visibleType: "charge",
                    chargeVisible: true
                  });
            }}>
            충전
          </Button>
        </div>
        {/* 충전 / 회수 */}
        {chargeVisible && (
          <FoundationChargeModal
            visible={chargeVisible}
            visibleType={visibleType}
            selectedData={selectedData}
            foundationIdx={foundationIdx}
            onCancel={() => {
              this.setState({ chargeVisible: false });
            }}
            refresh={this.getExternalFoundationBalanceList}
          />
        )}
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => `${record.idx}${record.coinType}`}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationBalance;
