import React, { Component } from "react";

import { Table, DatePicker, Button } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat, dayFormat, amountFormat, statFormat, checkCoinName } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import Const from "../../const";

const { RangePicker } = DatePicker;
class TransferStatistics extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchTypes: "ALL",
    startDate: "",
    endDate: "",
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getTransferStatisticsList({ pageNum: pager.current });
    }
  };

  // 검색 종류 선택
  searchTypeHandleChange = (value) => {
    this.setState({
      searchTypes: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
  };

  // 거리 통계 목록 조회
  getTransferStatisticsList = (params = {}) => {
    const { searchTypes, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    let p2pFlag = 0;
    if (Const.isP2PFlag) p2pFlag = 1;

    httpGet(
      httpUrl.transferStatisticsList,
      [10, pageNum, searchTypes, startDate, endDate, p2pFlag],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.transferList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTransferStatisticsList();
  }

  render() {
    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "transferType",
        className: "text-center",
        render: (data) => <div>{string.sendString[data]}</div>
      },
      {
        title: "전송 수",
        dataIndex: "transferCount",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "전송한 총 회원 수",
        dataIndex: "transferFromUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "전송받은 총 회원 수",
        dataIndex: "transferToUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "총 금액",
        dataIndex: "transferAmount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">거래 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            대상{" "}
            <SelectBox
              placeholder="선택해주세요."
              value={string.transferString[this.state.searchTypes]}
              code={string.transfeCode}
              codeString={string.transferString}
              onChange={(value) => {
                if (value !== this.state.searchTypes) this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
          </label>
          <label
            htmlFor=""
            style={{
              marginRight: "20px"
            }}>
            검색기간{" "}
            <RangePicker
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                this.getTransferStatisticsList();
              }}>
              검색
            </Button>
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="transfer-table"
          rowKey={(record) => `${record.statDate}${record.coinType}${record.transferType}`}
          columns={statisticsColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default TransferStatistics;
