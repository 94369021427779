import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { numberFormat } from "../../util";
import SelectBox from "../common/SelectBox";
import { showAlert } from "../common/AlertModal";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

class QnaCategoryModal extends Component {
  state = {
    list: [],
    isRegist: false,
    isModify: false
  };

  // 삭제
  onDelete = (value, row) => {
    if (global.confirm(`${row.name}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPut(httpUrl.qnaCategoryDelete, [row.idx], {})
        .then((result) => {
          if (row.deleted) showAlert("restore");
          else showAlert("delete");
          this.getQnaCategroyList();
          this.props.refresh();
        })
        .catch((error) => {});
    }
  };

  // 카테고리 생성 및 수정
  onUpdate = () => {
    const { name, selectedIdx, isModify } = this.state;
    let data = { name };
    if (isModify) data = { idx: selectedIdx, name };

    if (global.confirm(`${isModify ? "수정" : "생성"} 하시겠습니까?`))
      httpPut(httpUrl.qnaCategoryUpdate, [], data)
        .then((res) => {
          // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
          if (res.data.result === "SUCCESS") {
            this.getQnaCategroyList();
            this.props.refresh();

            if (isModify) showAlert("update");
            else showAlert("create");

            this.setState({
              isRegist: false,
              isModify: false
            });
          } else ShowErrorAlert(res.data.data, "name");
        })
        .catch((e) => {});
  };

  // 카테고리 목록
  getQnaCategroyList = () => {
    httpGet(httpUrl.qnaCategoryList, [false], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getQnaCategroyList();
  }

  render() {
    const { isRegist, isModify, selectedIdx, list } = this.state;
    const { visible, onCancel } = this.props;

    const columns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "카테고리",
        dataIndex: "name",
        className: "text-center",
        render: (data, row) =>
          isModify && selectedIdx === row.idx ? (
            <Input
              value={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            />
          ) : (
            data
          )
      },
      {
        title: "",
        dataIndex: "update",
        className: "text-center",
        render: (data, row) =>
          isModify && selectedIdx === row.idx ? (
            <div>
              <Button className="m-r-10" onClick={this.onUpdate}>
                수정
              </Button>
              <Button
                onClick={() => {
                  this.setState({ isModify: false });
                }}>
                취소
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                this.setState({
                  isRegist: false,
                  isModify: true,
                  selectedIdx: row.idx,
                  name: row.name
                });
              }}>
              수정
            </Button>
          )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(value, row);
              }
            }}
          />
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        footer={false}
        title="카테고리 목록"
        onCancel={onCancel}
        style={{ minWidth: "800px" }}>
        {isRegist ? (
          <div className="flex-row flex-center m-b-10">
            <Input
              style={{ width: "200px", marginRight: 10 }}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            />
            <Button className="m-r-10" onClick={this.onUpdate}>
              생성하기
            </Button>
            <Button
              onClick={() => {
                this.setState({ isRegist: false });
              }}>
              취소
            </Button>
          </div>
        ) : (
          <Button
            className="m-b-10"
            onClick={() => {
              this.setState({ isRegist: true, isModify: false, name: "" });
            }}>
            생성
          </Button>
        )}
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default QnaCategoryModal;
