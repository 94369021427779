import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, Input, DatePicker, Button, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl, httpDownload } from "../../api/httpClient";

import string from "../../string";
import {
  numberFormat,
  formatDate,
  amountFormat,
  startMonthBeforeFormat,
  startDateFormat,
  endDateFormat,
  excelFormat,
  checkData,
  checkCoinName,
  formatDateNumber
} from "../../util";
import LockDetail from "../../components/foundation/LockDetail";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import moment from "moment";

const Search = Input.Search;
const { RangePicker } = DatePicker;
class FoundationLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundationList: [],
      fCoinList: [],

      logList: [],
      pagination: {
        current: 0,
        total: 0
      },
      orderType: "CREATE_DATE",
      requestType: "ALL",
      orderByType: "ASC",
      searchText: "",
      startDate: "",
      endDate: "",
      lockVisible: false,
      lockSeleted: {},
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getFoundationLog({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 기준 선택
  orderTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true
      });
    }
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true
      });
    }
  };

  // 전송 상태 선택
  requestTypeHandleChange = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState({
        requestType: value,
        isLoaded: true
      });
    }
  };

  // 기간 설정
  handleChangeDate = (value) => {
    let newDate = new Date();
    this.setState({
      startDate:
        value.length !== 0
          ? startDateFormat(value[0])
          : startMonthBeforeFormat(newDate),
      endDate:
        value.length !== 0 ? endDateFormat(value[1]) : endDateFormat(newDate),
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 검색
  onSearch = (value) => {
    const { coinType, startDate, endDate } = this.state;
    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value
        },
        () => {
          this.getFoundationLog();
        }
      );
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      coinType,
      pagination,
      orderType,
      requestType,
      orderByType,
      startDate,
      endDate,
      searchText,
      isLoaded
    } = this.state;

    if (!coinType || !startDate || !endDate) {
      alert("코인 종류 및 검색 기간 날짜를 선택해주세요.");
    } else {
      // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
      if (isLoaded) {
        alert("조회버튼을 먼저 클릭해주세요.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.foundationCoinLogExcel,
            [
              total,
              1,
              coinType,
              orderType,
              requestType,
              orderByType,
              startDate,
              endDate,
              searchText
            ],
            {}
          )
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(
                res,
                `${excelFormat(startDate)} ~ ${excelFormat(
                  endDate
                )} ${checkCoinName(coinType)} 재단 코인 배포 내역.xlsx`
              );
            })
            .catch((error) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  // 재단 코인 배포 내역 조회
  getFoundationLog = (params = {}) => {
    const {
      coinType,
      orderType,
      requestType,
      orderByType,
      startDate,
      endDate,
      searchText
    } = this.state;
    let pageNum = params.pageNum || 1;
    // console.log(`---- startDate = ${startDate} endDate = ${endDate} ----`);
    if (coinType && startDate && endDate) {
      httpGet(
        httpUrl.foundationCoinLog,
        [
          10,
          pageNum,
          coinType,
          orderType,
          requestType,
          orderByType,
          startDate,
          endDate,
          searchText
        ],
        {}
      )
        .then((res) => {
          // console.log(`---- foundationLog ----`);
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          const pagination = { ...this.state.pagination };
          pagination.current = res.data.currentPage;
          pagination.total = res.data.totalCount;
          this.setState({
            logList: res.data.logList === null ? [] : res.data.logList,
            pagination,
            isLoaded: false
          });
        })
        .catch((error) => {});
    }
  };

  componentDidMount() {
    // 재단 코인 목록 조회
    httpGet(httpUrl.getFoundationList, [], {})
      .then((res) => {
        // console.log(`---- foundationCoin ----`);
        // console.log(JSON.stringify(res, null, 4));
        let newDate = new Date();
        this.setState(
          {
            foundationList: res.data,
            fCoinList: res.data.map((item) => {
              return item.coinType;
            }),
            coinType: res.data[0].coinType,
            startDate: startMonthBeforeFormat(newDate),
            endDate: endDateFormat(newDate)
          },
          () => this.getFoundationLog()
        );
      })
      .catch((error) => {});
  }

  render() {
    const {
      logList,
      coinType,
      fCoinList,
      orderType,
      requestType,
      orderByType,
      lockVisible,
      lockSeleted
    } = this.state;
    const dateFormat = "YYYY-MM-DD";
    let newDate = new Date();
    let startDate =
      newDate.getFullYear() +
      "-" +
      formatDateNumber(newDate.getMonth()) +
      "-" +
      formatDateNumber(newDate.getDate());

    const lockColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 120,
        title: "배포일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "배포 종류",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.lockString[data]}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "to",
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => (
          <div>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </div>
        )
      },
      {
        width: 180,
        title: "trxId",
        dataIndex: "trxId",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              width: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: "전송 상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      },
      {
        title: "배포자",
        dataIndex: "adminId",
        className: "text-center"
      },
      {
        title: "LOCK 상세",
        dataIndex: "detail",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            onClick={() => {
              this.setState({
                lockSeleted: row,
                lockVisible: true
              });
            }}
          >
            상세
          </Button>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">재단 코인 배포 내역</div>
        <div className="wrapper mt-35 width-1240" />
        {/* lock 상세목록 */}
        {lockVisible && (
          <LockDetail
            title="배포 내역 별 LOCK 상세 현황"
            visible={lockVisible}
            selected={lockSeleted}
            onCancel={() => {
              this.setState({
                lockVisible: false
              });
            }}
          />
        )}
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={fCoinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.foundationLogString[orderType]}
              code={string.foundationLogCode}
              codeString={string.foundationLogString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            검색기간{" "}
            <RangePicker
              locale={locale}
              // placeholder="시작일"
              defaultValue={[
                moment(startDate, dateFormat),
                moment(newDate, dateFormat)
              ]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </label>
          <div style={{ marginBottom: "10px", float: "right" }}>
            <label htmlFor="" style={{ marginRight: "20px" }}>
              대상{" "}
              <SelectBox
                placeholder="선택해주세요."
                value={string.requestLogString[requestType]}
                code={string.requestLogCode}
                codeString={string.requestLogString}
                onChange={(value) => {
                  if (value !== requestType)
                    this.requestTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <label htmlFor="">
              검색기준{" "}
              <Search
                placeholder="아이디, 주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px" }}
              />
            </label>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <span>{`total : ${numberFormat(this.state.pagination.total)}`}</span>
          <Button
            onClick={this.downloadExcel}
            disabled={logList.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={lockColumns}
          dataSource={logList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default FoundationLog;
