import React, { Component } from "react";
import bigDecimal from "js-big-decimal";

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Row,
  Upload
} from "antd";
import { httpGet, httpPost, httpUrl, serverUrl } from "../../api/httpClient";

import { coinMultiFormat, numberFormat } from "../../util";
import { showAlert } from "../../components/common/AlertModal";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const FormItem = Form.Item;
const TokenStatus = Form.create()(
  class extends Component {
    state = {
      coinType: 0,
      total: 0,
      user: 0,
      foundation: 0,
      out: 0
    };

    // 코인 종류 선택
    coinTypeHandleChange = (value) => {
      this.setState({
        coinType: value
      });
    };

    // 배포
    onSubmit = (e) => {
      const { form } = this.props;
      let createData = form.getFieldsValue();

      if (createData.amount) {
        createData = {
          ...createData,
          amount: coinMultiFormat(createData.amount, createData.coinType)
        };
      }

      if (!createData.memo) {
        createData = { ...createData, memo: null };
      }

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          if (global.confirm("코인 배포를 하시겠습니까?")) {
            httpPost(httpUrl.manualSingleCreate, [], createData)
              .then((res) => {
                if (res.data.result !== "FAIL") {
                  showAlert("send");
                  this.props.form.resetFields();
                  this.setState({
                    coinType: 0
                  });
                  this.getTokenState();
                } else ShowErrorAlert(res.data.data);
              })
              .catch((error) => {});
          }
          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
        }
      });
    };

    getTokenState = () => {
      httpGet(httpUrl.getTokenState, [], {})
        .then((res) => {
          // console.log(`token status = ${JSON.stringify(res.data, null, 4)}`);
          let total = res.data.find((data) => data.type === "TOTAL").amount;
          let foundation = res.data.find(
            (data) => data.type === "FOUNDATION"
          ).amount;
          let user = res.data.find((data) => data.type === "USER").amount;
          // let out = total - (user + foundation);
          let out = bigDecimal.getPrettyValue(total - (user + foundation));
          this.setState({
            total,
            foundation,
            user,
            out
          });
        })
        .catch((e) => {});
    };

    componentDidMount() {
      this.getTokenState();
    }

    render() {
      const { total, foundation, user, out, coinType } = this.state;
      const { getFieldDecorator } = this.props.form;

      const props = {
        action: serverUrl + httpUrl.manualCreate,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm("배포 하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s =
            "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
          Modal.info({
            title: "배포결과",
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- 실패한 배포\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {}
          });
          // if (file.data.failAddresses.length > 0) {
          //   file.data.failAddresses = file.data.failAddresses.map(
          //     value => "\n" + value
          //   );
          //   s = s + "- 실패한 Card" + file.data.failAddresses;
          // }
          // alert(s);
        },
        onError(err) {
          Modal.error({
            title: "배포결과",
            content: "파일 업로드 실패"
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <div>
          <div className="title">토큰현황</div>
          <div style={{ height: 30 }} />
          <div
            className="flex-row flex-sb"
            style={{ width: "70%", margin: "0 auto" }}
          >
            <div style={{ fontSize: 20 }}>
              <div>{numberFormat(total)}</div>
              <div>총 발행량</div>
            </div>
            <div style={{ fontSize: 20 }}>
              <div>{numberFormat(user)}</div>
              <div>회원보유수량</div>
            </div>
            <div style={{ fontSize: 20 }}>
              <div>{numberFormat(foundation)}</div>
              <div>재단 보유수량</div>
            </div>
            <div style={{ fontSize: 20 }}>
              <div>{out}</div>
              <div>외부 보유수량</div>
            </div>
          </div>
          <div style={{ height: 40 }} />
          <Form
            {...formItemLayout}
            onSubmit={this.onSubmit}
            style={{
              margin: "0 auto",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div style={{ height: 50 }} />
            <FormItem label={<span></span>} colon={false}>
              <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                <Button style={{ marginRight: "20px" }}>
                  <Icon type="upload" style={{ fontSize: "20px" }} /> 파일
                  업로드
                </Button>
              </Upload>
              <a href={"/airdropFormat.xlsx"} download>
                <Button>
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  양식 다운로드
                </Button>
              </a>
              <span style={{ color: "blue", marginLeft: "10px" }}>
                * 여러 사람에게 배포 시 파일업로드 사용
              </span>
            </FormItem>
            <FormItem label={<span>coinType&nbsp;</span>}>
              <Row>
                <Col span={6}>
                  {getFieldDecorator("coinType", {
                    rules: [
                      {
                        required: true,
                        message: "코인 종류를 선택해주세요!"
                      }
                    ]
                  })(
                    <CoinSelectBox
                      onChange={this.coinTypeHandleChange}
                      placeholder="선택해주세요."
                      style={{ width: "160px" }}
                    />
                  )}
                </Col>
                <Col span={18}>
                  {coinType === 3 && (
                    <FormItem style={{ marginBottom: 0 }}>
                      {getFieldDecorator("destinationTag", {
                        rules: [
                          {
                            required: true,
                            message: "destinationTag를 입력해주세요."
                          }
                        ]
                      })(
                        <Input
                          allowClear={true}
                          placeholder="destinationTag"
                          style={{ width: "300px" }}
                        />
                      )}
                    </FormItem>
                  )}
                </Col>
              </Row>
            </FormItem>
            <FormItem label={<span>address&nbsp;</span>}>
              {getFieldDecorator("to", {
                rules: [
                  {
                    required: true,
                    message: "주소를 입력해주세요!"
                  }
                ]
              })(
                <Input
                  allowClear={true}
                  style={{ width: "360px" }}
                  placeholder="주소를 입력해주세요"
                />
              )}
            </FormItem>
            <FormItem label={<span>amount&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "amount를 입력해주세요!"
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (value && coinType === 0) {
                        cb("코인 종류를 선택해주세요.");
                      }
                      cb();
                    }
                  }
                ]
              })(
                <InputNumber
                  allowClear
                  max={foundation}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "300px" }}
                  placeholder="수량을 입력해주세요."
                />
              )}
            </FormItem>
            <FormItem label={<span>메모&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(
                <Input.TextArea
                  placeholder="메모를 입력해주세요"
                  allowClear={true}
                  style={{ width: "360px", minHeight: "30px" }}
                />
              )}
            </FormItem>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                보내기
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }
  }
);

export default TokenStatus;
