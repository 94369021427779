import React, { Component } from "react";

import { Button, Descriptions, Input, Select, Table } from "antd";

import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { formatDate, numberFormat } from "../../util";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import QnaCategoryModal from "../../components/community/QnaCategoryModal";

const Option = Select.Option;
const Ditems = Descriptions.Item;
class Qna extends Component {
  state = {
    categoryList: [],

    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    categoryIdx: 0,
    status: "ALL",

    isModify: false,
    answer: "",

    visible: false,
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getQnaList({ pageNum: pager.current });
    }
  };

  // 카테고리 선택
  handleChangeCategory = (value) => {
    this.setState({
      categoryIdx: value,
      isLoaded: true
    });
  };

  // 상태 선택
  handleChangeStatus = (value) => {
    this.setState({
      status: value,
      isLoaded: true
    });
  };

  // 답변등록
  onUpdate = (row) => {
    let data = {
      idx: this.state.selectIdx,
      answer: this.state.answer
    };

    if (global.confirm(`${row.answer === null ? "등록" : "수정"}하시겠습니까?}`))
      httpPut(httpUrl.qnaUpdate, [], data)
        .then((res) => {
          // console.log(`createUpdate data= ${JSON.stringify(res.data, null, 4)}`);
          showAlert(row.answer === null ? "regist" : "update");
          this.setState({ isModify: false, selectIdx: null, answer: "" });
          this.getQnaList({ pageNum: this.state.pagination.current });
        })
        .catch((e) => {});
  };

  // 1:1 문의 목록
  getQnaList = (params = {}) => {
    let pageNum = params.pageNum || 1;
    const { categoryIdx, status } = this.state;

    httpGet(httpUrl.qnaList, [10, pageNum, categoryIdx, status], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;

        this.setState({
          list: res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  // 카테고리 목록
  getQnaCategroyList = () => {
    httpGet(httpUrl.qnaCategoryList, [true], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        this.setState({ categoryList: [{ idx: 0, name: "전체" }, ...res.data] });
      })
      .catch((e) => {});
  };

  refresh = () => {
    this.getQnaList();
    this.getQnaCategroyList();
  };

  componentDidMount() {
    this.getQnaList();
    this.getQnaCategroyList();
  }

  render() {
    const {
      visible,
      isModify,
      selectIdx,
      answer,
      categoryList,
      categoryIdx,
      status,
      list,
      pagination
    } = this.state;

    const columns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 300,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "카테고리",
        dataIndex: "category",
        className: "text-center"
      },
      {
        title: "제목",
        dataIndex: "title",
        className: "text-center"
      },
      {
        title: "작성자",
        dataIndex: "userId",
        className: "text-center"
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.qnaString[data]}</div>
      },
      {
        title: "처리일시",
        dataIndex: "answerDate",
        className: "text-center",
        render: (data) => <div>{data ? formatDate(data) : "-"}</div>
      }
    ];

    const expandedRowRender = (record) => {
      return (
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          style={{ backgroundColor: "white" }}>
          <Ditems label="질문" span={2}>
            {record.content}
          </Ditems>
          <Ditems label="답변" span={2}>
            <div>
              {selectIdx === record.idx && isModify ? (
                <Input
                  value={answer}
                  onChange={(e) => {
                    this.setState({ answer: e.target.value });
                  }}
                />
              ) : (
                record.answer
              )}
            </div>
            {selectIdx === record.idx && isModify ? (
              <div className="flex-row flex-center m-t-10">
                <Button className="m-r-10" onClick={() => this.onUpdate(record)}>
                  {record.answer === null ? "등록하기" : "수정하기"}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ isModify: false });
                  }}>
                  취소
                </Button>
              </div>
            ) : (
              <Button
                className="m-t-10"
                onClick={() => {
                  this.setState({ isModify: true, selectIdx: record.idx, answer: record.answer });
                }}>
                {record.answer === null ? "답변" : "수정"}
              </Button>
            )}
          </Ditems>
        </Descriptions>
      );
    };

    return (
      <div>
        {visible && (
          <QnaCategoryModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.refresh}
          />
        )}
        <div className="title">1:1 문의</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true });
            }}>
            카테고리 목록
          </Button>
          <div className="flex-row flex-center">
            <label className="flex-row flex-center m-r-10">
              카테고리&nbsp;&nbsp;
              <Select
                value={categoryIdx}
                style={{ width: "160px" }}
                onChange={this.handleChangeCategory}>
                {categoryList.map((category) => (
                  <Option key={category.idx} value={category.idx}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label className="flex-row flex-center m-r-10">
              상태&nbsp;&nbsp;
              <SelectBox
                style={{ width: "160px" }}
                code={string.qnaCode}
                codeString={string.qnaString}
                value={string.qnaString[status]}
                onChange={this.handleChangeStatus}
              />
            </label>
            <Button onClick={this.getQnaList}>검색</Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default Qna;
