import React, { Component } from "react";

import { Table, DatePicker, Button } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";
import { numberFormat, dayFormat, statFormat } from "../../util";

const { RangePicker } = DatePicker;
class UserStatistics extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    startDate: "",
    endDate: "",
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getUserStatistics({ pageNum: pager.current });
    }
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? statFormat(value[0]) : "",
      endDate: value.length !== 0 ? statFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 회원 통게 목록 조회
  getUserStatistics = (params = {}) => {
    const { startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.userStatisticsList, [10, pageNum, startDate, endDate], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.userList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getUserStatistics();
  }

  render() {
    const { list, pagination } = this.state;
    const statisticsColumns = [
      {
        title: "날짜",
        dataIndex: "statDate",
        // key: 'statDate',
        // width: 80,
        className: "text-center",
        render: (data) => <div>{dayFormat(data)}</div>
      },
      {
        title: "회원가입한 회원",
        dataIndex: "joinUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "탈퇴한 회원",
        dataIndex: "exitUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "총 회원 수",
        dataIndex: "totalUser",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">회원 통계</div>
        <div className="wrapper mt-35 width-1240" />
        <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
          <label className="m-r-10">검색기간&nbsp;</label>
          <RangePicker
            locale={locale}
            onChange={(date) => {
              this.handleChangeDate(date);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.getUserStatistics();
            }}>
            검색
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="admin-table"
          // rowKey={record => record.statDate}
          columns={statisticsColumns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UserStatistics;
