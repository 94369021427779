import React, { Component } from "react";

import { Button, Checkbox, Input, Table } from "antd";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";

import { checkData, formatDate, numberFormat } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import PartnerRegistry from "../../components/partner/PartnerRegistry";
import PartnerDetail from "../../components/partner/PartnerDetail";
import PaymentSetting2 from "../../components/payment/PaymentSetting2";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;
class PartnersStatus extends Component {
  state = {
    list: [],
    pagination: {
      current: 1,
      total: 0
    },
    franchiseType: "ALL",
    deleted: false,
    searchText: "",

    createVisible: false,
    detailVisible: false,
    detailData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getOrganiztionList({ pageNum: pager.current });
  };

  // 가맹점 조건 선택
  franchiseTypeHandleChange = (value) => {
    this.setState({
      franchiseType: value
    });
  };

  // 삭제 포함 여부 설정
  deleteHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getOrganiztionList();
      }
    );
  };

  // 삭제 및 복구
  onDelete = (row) => {
    const { pagination, list, deleted } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    if (global.confirm(`${row.name}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPut(httpUrl.organizationDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");
          this.getOrganiztionList({ pageNum: pageNum });
        })
        .catch((error) => {});
    }
  };

  getOrganiztionList = (params = {}) => {
    const { deleted, franchiseType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getOrganiztionList, [10, pageNum, deleted, franchiseType, searchText], {})
      .then((res) => {
        // console.log(JSON.stringify(res.data, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.getOrganiztionList();
  }

  render() {
    const { createVisible, detailVisible, paymentVisible, list } = this.state;

    const columns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "파트너사명",
        dataIndex: "name",
        className: "text-center",
        render: (data, row, index) => {
          return (
            <div
              key={index}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                this.setState({
                  detailVisible: true,
                  detailData: row
                });
              }}>
              {data}
            </div>
          );
        }
      },
      {
        title: "사업자번호",
        dataIndex: "businessNum",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "정산 수수료율(%)",
        dataIndex: "feeRatio",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "결제코인",
        dataIndex: "payment",
        className: "text-center",
        render: (data, row) => (
          <Button
            style={{ width: "80px" }}
            onClick={() => {
              this.setState({
                paymentVisible: true,
                organizationIdx: row.idx
              });
            }}>
            설정
          </Button>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 생성 */}
        {createVisible && (
          <PartnerRegistry
            visible={createVisible}
            onOk={this.getOrganiztionList}
            onCancel={() => {
              this.setState({
                createVisible: false
              });
            }}
          />
        )}
        {/* 상세 */}
        {detailVisible && (
          <PartnerDetail
            modalData={this.state.detailData}
            visible={detailVisible}
            onOk={() => {
              this.setState({
                detailVisible: false
              });
              this.getOrganiztionList({
                pageNum: this.state.pagination.current
              });
            }}
            onCancel={() => {
              this.setState({
                detailVisible: false
              });
            }}
          />
        )}
        {/* 결제 및 페이백 */}
        {paymentVisible && (
          <PaymentSetting2
            organizationIdx={this.state.organizationIdx}
            visible={paymentVisible}
            onCancel={() => {
              this.setState({
                paymentVisible: false
              });
            }}
          />
        )}
        <div className="title">파트너사 현황</div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}>
            생성
          </Button>
          <div className="flex-row flex-center">
            <label htmlFor="">검색조건&nbsp;&nbsp;</label>
            <Checkbox onChange={this.deleteHandleChange}>삭제 포함</Checkbox>
            <Search
              placeholder="가맹점명, 사업자번호를 입력해주세요."
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 400 }}
            />
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PartnersStatus;
