import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
// import { NaverMap } from "react-naver-maps";
import Axios from "axios";
import crypto from "crypto";

import { Form, Modal, Input, Button, Icon, Tag, Upload } from "antd";
import { httpPost, httpUrl, httpGet, serverUrl } from "../../api/httpClient";

import Address from "./Address";
import BasicImage from "../common/BasicImage";
import { showAlert } from "../common/AlertModal";
import PaymentSettingRegistry2 from "./PaymentSettingRegistry2";

const FormItem = Form.Item;

const OrganizationRegistry = Form.create()(
  class extends Component {
    state = {
      userList: [],
      searchText: "",

      userVisible: false,
      selectedUser: {},
      selectedRecommender: {},
      locationPoint: "0,0",
      franchise: false,
      auto: true,
      orgCategory: [],
      paymentSetting: [],
      image: "",
      thumbnail: "",
      preview: "",

      // 원본 이미지
      subImages: [],
      // 300*300 이미지
      subPreview: []
    };

    randomString = () => {
      let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let str_length = 18;
      let random_str = "";
      for (var i = 0; i < str_length; i++) {
        let random_num = Math.floor(Math.random() * chars.length);
        random_str += chars.substring(random_num, random_num + 1);
      }
      // console.log(JSON.stringify(random_str));
      this.setState({ externalKey: random_str });
    };

    onSelect = (data) => {
      const { type } = this.state;

      if (type === "user") {
        this.setState({ selectedUser: data });
        this.props.form.setFieldsValue({ userIdx: data.idx });
      } else {
        this.setState({ selectedRecommender: data });
        this.props.form.setFieldsValue({ parentIdx: data.idx });
      }
    };

    // 대표 이미지 삭제
    handleDeleteFile = (image) => {
      if (global.confirm("이미지를 삭제 하시겠습니까?")) {
        this.setState({ preview: "" });
      }
    };

    // 상세 이미지 삭제
    handleSubImgDeleteFile = (image) => {
      const { subPreview, subImages } = this.state;

      // 원본 이미지 삭제 작업
      let idx = 0;
      subPreview.forEach((list, index) => {
        if (list.subPhoto.includes(image)) return (idx = index);
      });
      // console.log(`index = ${idx}`);
      let subImg = [];
      if (subImages.length !== 0) {
        subImages.forEach((list, index) => {
          if (index !== idx) subImg.push({ photo: list.photo });
        });
      }
      // 300*300 이미지
      let arr = [];
      subPreview.forEach((list) => {
        if (!list.subPhoto.includes(image)) arr.push({ subPhoto: list.subPhoto });
        return arr;
      });

      this.setState({ subImages: subImg, subPreview: arr });
    };

    // 주소 찾기
    addressSeach = (value) => {
      // console.log(JSON.stringify(value, null, 4));
      this.setState({ addressVisible: false });
      this.props.form.setFieldsValue({ locationAddress: value });
      /*  주소 좌표 */
      httpGet(httpUrl.getGeocode, [value]).then((res) => {
        let address = "";
        if (res.data.y != 0 && res.data.x != 0) address = `${res.data.y}, ${res.data.x}`;
        this.props.form.setFieldsValue({ locationPoint: address });
        this.setState({ locationPoint: address });
      });
    };

    // 페이백 설정
    onPaymentSetting = (data) => {
      let arr = [...this.state.paymentSetting];
      arr.push(data);
      this.setState({
        paymentSetting: arr
      });
      this.props.form.setFieldsValue({ paymentSetting: arr });
    };

    handleClose = (removedTag) => {
      const paymentSetting = this.state.paymentSetting.filter(
        (tag) => tag.coinType !== removedTag.coinType
      );
      this.setState({ paymentSetting });
      this.props.form.setFieldsValue({ paymentSetting: paymentSetting });
    };

    // 생성
    onRegistry = (e) => {
      const { subImages, subPreview } = this.state;
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`values: ${JSON.stringify(values, null, 4)}`);

        // 비빌번호 설정
        let password = values.password + values.id;
        // console.log(`password = ${JSON.stringify(password)}`);
        let passwordHash = crypto
          .createHash("sha256")
          .update(password, "utf8")
          .digest()
          .toString("hex");
        // console.log(`passwordHash = ${JSON.stringify(passwordHash)}`);

        // 상세 이미지 - 원본이미지 + 300*300 이미지 병합 작업
        let temp = [];
        subImages.forEach((list, index) => {
          temp.push({ photo: list.photo, index });
        });

        let images = [];
        subPreview.forEach((list, index) => {
          temp.forEach((img) => {
            if (img.index === index)
              images.push({
                photo: img.photo,
                subPhoto: list.subPhoto
              });
          });
        });

        let createData = {
          ...values,
          password: passwordHash,
          image: this.state.image,
          thumbnail: this.state.thumbnail,
          images
        };
        // console.log(`createData: ${JSON.stringify(createData, null, 4)}`);
        if (!err) {
          if (global.confirm("생성하시겠습니까?"))
            httpPost(httpUrl.organizationUtopCreate, [], createData)
              .then((res) => {
                if (res.data.result === "SUCCESS") {
                  this.props.onOk();
                  form.resetFields();
                  showAlert("create");
                } else {
                  if (res.data.data === "DUPLICATE_USER_ID")
                    alert("가맹점 계정 아이디가 중복되었습니다. 다시 설정해주세요.");
                  if (res.data.data === "WRONG_EMAIL_TYPE")
                    alert("이메일 형식이 잘못되었습니다. 다시 설정해주세요.");
                  if (res.data.data === "COIN_NULL") alert("결제코인을 설정해주세요.");
                  if (res.data.data === "SERVER_ERROR") alert("서버 오류.");
                }
              })
              .catch((error) => {});
        }
      });
    };

    onCancel = () => {
      this.props.form.resetFields();
      this.props.onCancel();
    };

    componentDidMount() {}

    render() {
      const { settingVisible, addressVisible, preview, paymentSetting } = this.state;
      const { visible, form } = this.props;
      const { getFieldDecorator } = form;
      const coinList = reactLocalStorage.getObject("coinList");

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 }
        }
      };

      // 대표 이미지
      const uploadProps = {
        withCredentials: true,
        showUploadList: false,
        multiple: false,
        transformFile: async (file) => {
          let newImages = [];
          newImages = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const canvas1 = document.createElement("canvas");
              const canvas2 = document.createElement("canvas");
              const img = document.createElement("img");
              img.src = reader.result;
              this.setState({
                preview: reader.result
              });
              img.onload = () => {
                let image = [];
                const ctx1 = canvas1.getContext("2d");
                const ctx2 = canvas2.getContext("2d");
                canvas1.width = 100;
                canvas1.height = 100;
                ctx1.drawImage(img, 0, 0, 100, 100);
                canvas1.toBlob(
                  (blob) => {
                    image.push(new File([blob], `100x100${file.name}`));
                    // resolve(image);
                  },
                  "image/png",
                  0.5
                );
                canvas2.width = 300;
                canvas2.height = 300;
                ctx2.drawImage(img, 0, 0, 300, 300);
                canvas2.toBlob(
                  (blob) => {
                    image.push(new File([blob], `300x300${file.name}`));
                    resolve(image);
                  },
                  "image/png",
                  0.5
                );
              };
            };
          });
          await new Promise((resolve) => {
            const formData100 = new FormData();
            const formData300 = new FormData();
            formData100.append("file", newImages[0]);
            formData300.append("file", newImages[1]);
            Axios({
              method: "POST",
              url: serverUrl + httpUrl.imageUpload,
              data: formData100,
              withCredentials: true
            }).then((res) => {
              if (res.data.data.result) {
                this.setState({
                  thumbnail: res.data.data.fileId
                });
              } else {
                showAlert(res.data.data.reason);
              }
            });
            Axios({
              method: "POST",
              url: serverUrl + httpUrl.imageUpload,
              data: formData300,
              withCredentials: true
            }).then((res) => {
              if (res.data.data.result) {
                this.setState({
                  image: res.data.data.fileId
                });
              }
            });
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title="가맹점 생성"
          okText="생성"
          cancelText="취소"
          onOk={this.onRegistry}
          onCancel={this.onCancel}
          style={{ minWidth: "800px" }}>
          {settingVisible && (
            <PaymentSettingRegistry2
              visible={settingVisible}
              paymentSetting={this.state.paymentSetting}
              onCancel={() => {
                this.setState({ settingVisible: false });
              }}
              onOk={this.onPaymentSetting}
            />
          )}
          {addressVisible && (
            <Address
              visible={addressVisible}
              onCancel={() => {
                this.setState({ addressVisible: false });
              }}
              onOk={this.addressSeach}
            />
          )}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            <FormItem label={<span>가맹점 계정 아이디&nbsp;</span>}>
              {getFieldDecorator("id", {
                rules: [
                  {
                    required: true,
                    message: "가맹점 계정 아이디를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>가맹점 계정 이메일&nbsp;</span>}>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "가맹점 계정 이메일를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>가맹점 계정 비밀번호&nbsp;</span>}>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "가맹점 계정 비밀번호를 입력해주세요!"
                  }
                ]
              })(<Input.Password placeholder="" />)}
            </FormItem>
            <FormItem label={<span>가맹점 계정 보안비밀번호&nbsp;</span>}>
              {getFieldDecorator("securityPassword", {
                rules: [
                  {
                    required: true,
                    message: "가맹점 계정 보안비밀번호를 입력해주세요!"
                  }
                ]
              })(<Input.Password placeholder="" maxLength={4} />)}
            </FormItem>
            <FormItem label={<span>가맹점명&nbsp;</span>}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "가맹점명을 입력해주세요!"
                  }
                ]
              })(<Input placeholder="홍길동" />)}
            </FormItem>
            <FormItem label={<span>사업자번호&nbsp;</span>}>
              {getFieldDecorator("businessNum", {
                rules: [
                  {
                    required: false,
                    message: "사업자번호를 입력해주세요!"
                  },
                  {
                    required: false,
                    message: "숫자만 입력해주세요.",
                    pattern: /[0-9]+$/
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>전화번호&nbsp;</span>}>
              {getFieldDecorator("tel", {
                rules: [
                  {
                    required: false,
                    message: "전화번호를 입력해주세요!"
                  },
                  {
                    required: false,
                    message: "숫자만 입력해주세요.",
                    pattern: /[0-9]+$/
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>주소&nbsp;</span>}>
              {getFieldDecorator("locationAddress", {
                rules: [
                  {
                    required: false,
                    message: "주소를 입력해주세요!"
                  }
                ]
              })(
                <div>
                  {form.getFieldValue("locationAddress") && (
                    <div>{form.getFieldValue("locationAddress")}</div>
                  )}
                  <Button
                    onClick={() => {
                      this.setState({ addressVisible: true });
                    }}>
                    {form.getFieldValue("locationAddress") ? "주소 변경" : "주소 찾기"}
                  </Button>
                </div>
              )}
            </FormItem>
            <FormItem label={<span>상세주소&nbsp;</span>}>
              {getFieldDecorator("locationAddressDetail", {
                rules: [
                  {
                    required: false,
                    message: "상세주소를 입력해주세요!"
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>메모&nbsp;</span>}>
              {getFieldDecorator("memo", {
                rules: [
                  {
                    required: false,
                    message: "메모를 입력해주세요!"
                  }
                ]
              })(<Input.TextArea />)}
            </FormItem>
            <FormItem label={<span>대표 이미지&nbsp;</span>}>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(
                <div>
                  {preview !== "" && (
                    <div className="flex-row flex-sb flex-center">
                      <div style={{ maxWidth: "300px" }}>
                        <BasicImage image={preview} src={preview} style={{ maxWidth: "300px" }} />
                      </div>
                      <Icon
                        className="pointer"
                        type="delete"
                        onClick={() => {
                          this.handleDeleteFile(preview);
                        }}
                      />
                    </div>
                  )}
                  <Upload {...uploadProps}>
                    <Button>{preview ? "변경" : "추가"}</Button>
                  </Upload>
                </div>
              )}
            </FormItem>
            {/* {Const.isOrganizationFlag && (
              <FormItem label={<span>상세 이미지&nbsp;</span>}>
                {getFieldDecorator("images", {
                  rules: []
                })(
                  <div>
                    {subPreview.length !== 0 &&
                      subPreview.map((list, index) => (
                        <div
                          className="flex-row flex-sb flex-center"
                          style={{
                            marginBottom: "10px"
                          }}>
                          <div style={{ maxWidth: "300px" }}>
                            <BasicImage
                              image={list}
                              src={serverUrl + httpUrl.getImage + list.subPhoto}
                              style={{
                                maxWidth: "300px"
                              }}
                            />
                          </div>
                          <Icon
                            type="delete"
                            className="pointer"
                            onClick={() => {
                              this.handleSubImgDeleteFile(list.subPhoto);
                            }}
                          />
                        </div>
                      ))}
                    <Upload {...subUploadProps}>
                      <Button>추가</Button>
                    </Upload>
                  </div>
                )}
              </FormItem>
            )} */}
            <FormItem label={<span>결제코인 &nbsp;</span>}>
              {getFieldDecorator("paymentSetting", {
                rules: [
                  {
                    required: true,
                    message: "결제코인을 선택해주세요!"
                  }
                ]
              })(
                <div>
                  {paymentSetting.length !== 0 &&
                    paymentSetting.map((tag, index) => (
                      <Tag key={tag.coinType} closable={true} onClose={() => this.handleClose(tag)}>
                        {coinList && coinList.find((coin) => coin.coinType === tag.coinType)
                          ? coinList.find((coin) => coin.coinType === tag.coinType).name
                          : ""}
                      </Tag>
                    ))}
                  <Button
                    onClick={() => {
                      this.setState({ settingVisible: true });
                    }}>
                    {paymentSetting.length === 0 ? "선택" : "추가"}
                  </Button>
                </div>
              )}
            </FormItem>
            <FormItem label={<span>영업 시간&nbsp;</span>}>
              {getFieldDecorator("openTime", {
                rules: [
                  {
                    required: false,
                    message: "영업시간을 입력해주세요!"
                  }
                ]
              })(<Input.TextArea />)}
            </FormItem>
            <FormItem label={<span>기타&nbsp;</span>}>
              {getFieldDecorator("etc", {
                rules: [
                  {
                    required: false,
                    message: "기타를 입력해주세요!"
                  }
                ]
              })(<Input.TextArea />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default OrganizationRegistry;
