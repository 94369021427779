import React, { Component } from "react";

import { Modal, Table } from "antd";
import { formatDate, numberFormat } from "../../util";
import string from "../../string";

class CancelList extends Component {
  state = { list: [] };

  componentDidMount() {
    this.setState({
      list: this.props.list
    });
    // console.log(`this.props = ${JSON.stringify(this.props, null, 4)}`);
  }

  render() {
    const { visible, onCancel } = this.props;
    const { list } = this.state;
    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "취소 요청일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "취소 상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <Modal
        visible={visible}
        title="취소 목록 조회"
        onCancel={onCancel}
        footer={false}
        style={{ minWidth: "800px" }}
      >
        <div></div>
        <Table dataSource={list} columns={columns} pagination={false} />
      </Modal>
    );
  }
}

export default CancelList;
