import React, { Component } from "react";
import fileDownload from "js-file-download";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Icon, Table, Input } from "antd";
import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import string from "../../string";
import { amountFormat, checkCoinName, numberFormat } from "../../util";

import { showAlert } from "../../components/common/AlertModal";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import _const from "../../const";

const Search = Input.Search;
class HoldingStatus extends Component {
  state = {
    coinList: [],

    list: [],
    pagination: {
      total: 0,
      current: 1
    },
    orderType: "BALANCE",
    orderByType: "DESC",
    searchText: "",
    totalBalance: 0,
    totalLock: 0,
    totalAvailable: 0,

    changeData: [],
    isLoaded: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager, changeData: [] });

      this.getFoundationUserList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 기준 선택
  orderTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true
      });
    }
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true
      });
    }
  };

  // 검색
  onSearch = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      this.setState(
        {
          searchText: value,
          isLoaded: false
        },
        () => {
          this.getFoundationCount();
          this.getFoundationUserList();
        }
      );
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { coinType, pagination, orderType, orderByType, searchText, isLoaded } = this.state;

    if (coinType === 0 || coinType === undefined) {
      showAlert("coin");
    } else {
      if (isLoaded) {
        alert("검색버튼을 먼저 클릭해주세요.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          httpDownload(
            httpUrl.tokenHolingStatusExcel,
            [total, 1, coinType, orderType, orderByType, searchText],
            {}
          )
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(res, `${checkCoinName(coinType)} 토큰 보유 현황.xlsx`);
            })
            .catch((error) => {
              alert(`다운로드 실패`);
            });
        }
      }
    }
  };

  // 보유현황 목록 조회
  getFoundationUserList = (params = {}) => {
    const { coinType, orderType, orderByType, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.foundationUserList,
      [10, pageNum, coinType, orderType, orderByType, searchText],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.foundationUserList === null ? [] : res.data.foundationUserList,
          pagination,
          changeData: [],
          isLoaded: false
        });
      })
      .catch((error) => {});
  };

  // 코인 총 금액 조회
  getFoundationCount = (params = {}) => {
    const { coinType } = this.state;

    httpGet(httpUrl.getFoundationCount, [coinType], {})
      .then((res) => {
        this.setState({
          totalBalance: res.data.totalBalance,
          totalLock: res.data.totalLock,
          totalAvailable: res.data.totalAvailable
        });
      })
      .catch((e) => {});
  };

  // 잔액 갱신
  updateBalance = (data) => {
    httpGet(httpUrl.updateBalance, [data.address, data.coinType], {})
      .then((res) => {
        // console.log(`balance update = ${JSON.stringify(res, null, 4)}`);
        if (res.result === "SUCCESS") {
          let changeData = this.state.changeData;
          changeData.forEach((item) => {
            if (data.idx === item.idx) changeData.pop({ item });
          });
          // if (data.balance !== res.data.balance) {
          // }
          changeData.push({ ...data, ...res.data });

          this.setState({ changeData });
          this.getFoundationCount();
        }
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const coinList = reactLocalStorage.getObject("coinList");
    this.setState(
      {
        coinList: coinList.map((item) => {
          return item.coinType;
        }),
        coinType: _const.mainCoinType
      },
      () => {
        this.getFoundationCount();
        this.getFoundationUserList();
      }
    );
  }

  render() {
    const {
      changeData,
      coinType,
      coinList,
      orderByType,
      orderType,
      totalBalance,
      list,
      pagination
    } = this.state;

    const columns = [
      {
        key: "idx",
        title: "회원번호",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "보유수량",
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => (
          <div>
            {changeData.length !== 0
              ? changeData.find((item) => item.idx === row.idx)
                ? amountFormat(
                    changeData.find((item) => item.idx === row.idx).balance,
                    row.coinType
                  )
                : amountFormat(data, row.coinType)
              : amountFormat(data, row.coinType)}
            <Icon
              type="reload"
              style={{ marginLeft: 10 }}
              onClick={() => {
                this.updateBalance(row);
              }}
            />
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">토큰 보유 현황</div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="" style={{ marginRight: "20px" }}>
            코인{" "}
            <CoinSelectBox
              type="coinType"
              placeholder="선택해주세요."
              value={coinType}
              coinCode={coinList}
              onChange={(value) => {
                if (value !== coinType) this.coinTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            순서{" "}
            <SelectBox
              value={string.orderByString[orderByType]}
              code={string.orderByCode}
              codeString={string.orderByString}
              onChange={(value) => {
                if (value !== orderByType) this.orderByTypeHandleChange(value);
              }}
              style={{ width: "160px", marginRight: "10px" }}
            />
            <SelectBox
              value={string.foundationOrderString[orderType]}
              code={string.foundationOrderCode}
              codeString={string.foundationOrderString}
              onChange={(value) => {
                if (value !== orderType) this.orderTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <div style={{ float: "right" }}>
            <div className="flex-row flex-center">
              <label htmlFor="search">검색 기준&nbsp;&nbsp;</label>
              <Search
                placeholder="아이디, 지갑주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span>{`total : ${numberFormat(pagination.total)}`}</span>
          <span style={{ marginLeft: "20px" }}>
            {`총 보유수량 : ${numberFormat(totalBalance, coinType)}`}
          </span>
          <Button
            style={{ float: "right", marginBottom: "10px" }}
            onClick={this.downloadExcel}
            disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <div style={{ color: "blue" }} className="m-b-1">
            * 사용자가 잔액 갱신을 하지 않을 경우 총 수량이 일치 하지 않을 수 있습니다.
          </div>
          <div style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의 해당 코인에 대해
            모든 출금이 금지됩니다.
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default HoldingStatus;
