import React, { Component } from "react";
import fileDownload from "js-file-download";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Input, Button, Checkbox, DatePicker, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl, httpPut, httpDownload } from "../../api/httpClient";
import {
  numberFormat,
  formatDate,
  startDateFormat,
  endDateFormat,
  startThreeMonthBeforeFormat,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";
import WalletModal from "../../components/user/WalletModal";
import WalletLogModal from "../../components/user/WalletLogModal";

const Search = Input.Search;
const { RangePicker } = DatePicker;
class UserList extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 0
      },
      deleted: false,
      searchType: "ID",
      searchText: "",
      startDate: startThreeMonthBeforeFormat(date),
      endDate: endDateFormat(date),

      selectedSerial: "",
      walletVisible: false,
      walletLogVisible: false,
      coinLockVisible: false,
      isLoaded: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getUserList({ pageNum: pager.current });
    }
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 검색 대상 설정
  searchTypeHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      searchType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : "",
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getUserList();
      }
    );
  };

  // 회원 삭제 및 복구
  onDelete = (row) => {
    const { deleted, pagination } = this.state;

    let pageNum =
      pagination.current > 1 && !deleted && row.deleted === 0
        ? this.state.list.length === 1
          ? pagination.current - 1
          : pagination.current
        : pagination.current;

    if (global.confirm(`${row.id}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPut(httpUrl.userDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");
          this.getUserList({ pageNum });
        })
        .catch((error) => {});
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { isLoaded, pagination, searchText, deleted, searchType, startDate, endDate } =
      this.state;

    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.userExcelDownload,
          [total, 1, searchText, deleted, searchType, startDate, endDate],
          {}
        )
          .then((res) => {
            fileDownload(res, `회원목록 조회.xlsx`);
          })
          .catch((err) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 회원 정보 리스트
  getUserList = (params = {}) => {
    const { deleted, searchText, searchType, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.userList,
      [10, pageNum, deleted, searchText, searchType, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.userList === null ? [] : res.data.userList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    if (userInfo.authList) {
      this.getUserList();
    }
    // console.log(global.lanList);
  }

  render() {
    const {
      walletVisible,
      selectedSerial,
      walletLogVisible,
      searchType,
      startDate,
      endDate,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "회원번호",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "잔액",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => {
          return (
            <Button
              onClick={() => {
                this.setState({
                  selectedSerial: row,
                  walletVisible: true
                });
              }}>
              잔액조회
            </Button>
          );
        }
      },
      {
        title: "거래내역",
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.setState({
                  selectedSerial: row,
                  walletLogVisible: true
                });
              }}>
              내역보기
            </Button>
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== row.deleted) {
                  this.onDelete(row);
                }
              }}
            />
          )
      }
    ];

    return (
      <div>
        {/* 지갑정보 */}
        {walletVisible && (
          <WalletModal
            page={true}
            visible={walletVisible}
            selectedSerial={selectedSerial}
            onCancel={() => {
              this.setState({
                walletVisible: false
              });
            }}
          />
        )}
        {/* 거래내역 */}
        {walletLogVisible && (
          <WalletLogModal
            page={true}
            visible={walletLogVisible}
            selectedSerial={selectedSerial}
            onOk={() => {
              this.setState({
                walletLogVisible: false
              });
            }}
          />
        )}

        <div className="title">회원 관리</div>
        <div className="flex-row flex-center m-b-10 flex-sb">
          <div className="flex-row flex-center">
            <label>기간&nbsp;</label>
            <RangePicker
              locale={locale}
              defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
              format={dateFormat}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
              allowClear={false}
            />
          </div>
          <div className="flex-row flex-center">
            <label htmlFor="" style={{ marginRight: "10px" }}>
              조건&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>삭제 포함</Checkbox>
            </label>
            <label htmlFor="searchType">대상&nbsp;</label>
            <SelectBox
              value={searchType}
              style={{ width: "160px", marginRight: "10px" }}
              code={string.memberSearchTypeCode}
              codeString={string.searchTypeString}
              onChange={(value) => {
                if (value !== searchType) {
                  this.searchTypeHandleChange(value);
                }
              }}
            />
            <Search
              placeholder={`검색어를 입력해주세요.`}
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="member-table"
          className="flex-1"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UserList;
