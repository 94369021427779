import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import { httpGet, httpUrl } from "../../api/httpClient";

import {
  amountFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  endDateFormat,
  checkCoinName
} from "../../util";
import string from "../../string";
import CoinSelectBox from "../../components/common/CoinSelectBox";

const { RangePicker } = DatePicker;
const Search = Input.Search;
class CoinTransferLog extends Component {
  constructor(props) {
    const coinList = reactLocalStorage.getObject("coinList");
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 0
      },
      coinType: coinList.find((coin) => coin.symbol === "SWAPP")
        ? coinList.find((coin) => coin.symbol === "SWAPP").coinType
        : 0,
      searchText: "",
      startDate: "",
      endDate: "",
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getTransferLogList({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색 기간 설정
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getTransferLogList();
      }
    );
  };

  // 코인 입급 내역 조회
  getTransferLogList = (params = {}) => {
    const { coinType, searchText, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getTransferLogList, [10, pageNum, coinType, searchText, startDate, endDate], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTransferLogList();
  }

  render() {
    const { list, pagination } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    const columns = [
      {
        // width: 80,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "coinType",
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "전송 방법",
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{data === "M2I" ? "마케팅입금" : "-"}</div>
      },
      {
        title: "보낸 주소",
        dataIndex: "from",
        className: "text-center"
      },
      {
        title: "받은 사람",
        dataIndex: "toId",
        className: "text-center"
      },
      {
        title: "받은 주소",
        dataIndex: "to",
        className: "text-center"
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    return (
      <div>
        <div className="title">코인입금내역</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <div className="flex-row flex-center m-r-10">
            <label className="m-r-10">코인 종류&nbsp;</label>
            <CoinSelectBox
              value={
                coinList.find((coin) => coin.symbol === "SWAPP") &&
                coinList.find((coin) => coin.symbol === "SWAPP").coinType
              }
              onChange={this.coinTypeHandleChange}
              placeholder="선택해주세요."
              style={{ width: "160px" }}
            />
          </div>
          <div className="flex-row flex-center m-r-10">
            <label className="m-r-10">검색기간&nbsp;</label>
            <RangePicker
              // placeholder="시작일"
              locale={locale}
              onChange={(date) => {
                this.handleChangeDate(date);
              }}
            />
          </div>
          <div className="flex-row flex-center">
            <label className="m-r-10" htmlFor="">
              검색기준
            </label>
            <Search
              placeholder="받은 주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default CoinTransferLog;
