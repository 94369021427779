import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, Input, DatePicker, Button, Icon } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";
import {
  amountFormat,
  checkCoinName,
  checkData,
  endDateFormat,
  excelFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startThreeMonthBeforeFormat
} from "../../util";
import string from "../../string";

const Search = Input.Search;
const { RangePicker } = DatePicker;
class PaymentStatus extends Component {
  constructor(props) {
    super(props);
    let date = new Date();
    this.state = {
      list: [],
      pagination: {
        current: 0,
        total: 0
      },
      searchTypes: "ALL",
      searchText: "",
      startDate: startThreeMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      isLoaded: false
    };
  }

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getTokenPaymentList({ pageNum: pager.current });
    }
  };

  // 검색 날짜 선택
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : "",
      isLoaded: true
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  searchTypeHandleChange = (value) => {
    this.setState(
      {
        searchTypes: value,
        isLoaded: true
      },
      () => this.getTokenPaymentList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => this.getTokenPaymentList()
    );
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const { isLoaded, pagination, searchTypes, searchText, startDate, endDate } = this.state;

    // console.log(`title = ${title}`);
    if (isLoaded) {
      alert("조회버튼을 먼저 클릭해주세요.");
    } else {
      let result = [];
      let title = "토큰 지급 현황";

      if (startDate !== "" && endDate !== "") {
        result.push(`${excelFormat(startDate)} ~ ${excelFormat(endDate)} `);
      }

      let total = pagination.total <= 9 ? 10 : pagination.total;

      title =
        result.reduce((acc, item, index) => {
          return acc + item;
        }, "") + title;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.tokenPaymentListExcel,
          [total, 1, searchTypes, searchText, startDate, endDate],
          {}
        )
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            fileDownload(res, `${title}.xlsx`);
          })
          .catch((error) => {
            alert(`다운로드 실패`);
          });
      }
    }
  };

  // 배포 목록 조회
  getTokenPaymentList = (params = {}) => {
    const { searchTypes, searchText, startDate, endDate } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.tokenPaymentList,
      [10, pageNum, searchTypes, searchText, startDate, endDate],
      {}
    )
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.coinAirDropLogList === null ? [] : res.data.coinAirDropLogList,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTokenPaymentList();
  }

  render() {
    const { startDate, endDate, list, pagination } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "금액",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "배포자",
        dataIndex: "adminId",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "txId",
        dataIndex: "txId",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "상태",
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.logString[data]}</div>
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "300px"
            }}>
            {checkData(data)}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">토큰 지급 현황</div>
        <div className="flex-row flex-end m-b-10">
          <div className="flex-row flex-center">
            <label className="flex-row flex-center m-r-10">
              기간&nbsp;&nbsp;
              <RangePicker
                // placeholder="시작일"
                locale={locale}
                allowClear={false}
                format={dateFormat}
                defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>

            <label className="flex-row flex-center">
              <Search
                placeholder="주소를 입력하세요"
                enterButton="검색"
                allowClear
                onSearch={this.onSearch}
                style={{ width: "300px" }}
              />
            </label>
          </div>
        </div>
        <div className="flex-row flex-center flex-end m-b-10">
          <Button onClick={this.downloadExcel} disabled={list.length === 0 ? true : false}>
            <Icon type="download" style={{ fontSize: "20px" }} />
            엑셀 다운로드
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="distribute-log-table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default PaymentStatus;
