import React, { Component } from "react";

import { Table, Input, Button, Checkbox } from "antd";
import { httpUrl, httpPut, httpGet } from "../../api/httpClient";
import { numberFormat, formatDate, amountFormat, checkCoinName, checkData } from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "./../../components/common/CoinSelectBox";
import WalletRegistryModal from "../../components/wallet/WalletRegistryModal";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;
class FeeWallet extends Component {
  state = {
    list: [],
    activeType: "ALL",
    deletedType: false,
    searchText: "",

    selectedRow: "",
    isModify: false,
    editData: {
      coinType: 0,
      address: "",
      seed: "",
      memo: "",
      active: 0,
      deleted: 0
    },
    prevData: {},

    createModalVisible: false
  };

  // 활성화 조건 변경 여부
  activeTypeHandleChange = (value) => {
    this.setState({
      activeType: value
    });
  };

  // 삭제 계좌 포함 여부
  deletedTypeHandleChange = (e) => {
    this.setState({
      deletedType: e.target.checked
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getFeeWalletList();
      }
    );
  };

  // 생성
  onCreate = () => {
    showAlert("create");
    this.setState(
      {
        createModalVisible: false,
        activeType: "ALL",
        deletedType: false,
        searchText: ""
      },
      () => {
        this.getFeeWalletList();
      }
    );
  };

  // 데이터 변경
  onChangeModal = (value, type) => {
    var data = Object.assign({}, this.state.editData);
    data[type] = value;
    this.setState({
      editData: data
    });
  };

  // 수정
  handleFeeWalletUpdate = () => {
    const { editData } = this.state;

    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.feewWalletUpdate, [], editData)
        .then((res) => {
          showAlert("update");
          this.getFeeWalletList();
          this.setState({
            selectedRow: "",
            isModify: false
          });
        })
        .catch((e) => {});
    }
  };

  // 삭제
  onDelete = (value, row) => {
    if (
      global.confirm(
        `${checkCoinName(row.coinType)}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    )
      httpPut(httpUrl.feeWalletDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");
          this.getFeeWalletList();
        })
        .catch((error) => {});
  };

  // 출금지갑 리스트
  getFeeWalletList = (params = {}) => {
    const { activeType, deletedType, searchText } = this.state;
    httpGet(httpUrl.feeWalletList, [activeType, deletedType, searchText], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getFeeWalletList();
  }

  render() {
    const { selectedRow, editData, isModify } = this.state;
    const feeWalletColumns = [
      {
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data, row, index) => <div key={`createDate${index}`}>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              checkCoinName(data)
            ) : (
              <CoinSelectBox
                style={{ width: "200px" }}
                value={checkCoinName(editData.coinType)}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "지갑",
        dataIndex: "address",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              data
            ) : (
              <Input
                allowClear
                value={editData.address}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "address");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "잔액",
        dataIndex: "balance",
        // width: 160,
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data, row, index) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {selectedRow !== index ? (
              checkData(data)
            ) : (
              <Input.TextArea
                style={{ height: "50px" }}
                value={editData.memo}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "memo");
                }}
              />
            )}
          </div>
        )
      },
      {
        title: "활성화",
        dataIndex: "active",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index ? (
              string.activeHandlesCodes[string.toggleCode[data]]
            ) : (
              <SelectBox
                type="active"
                value={string.activeHandlesCodes[editData.active]}
                code={string.toggleCode}
                codeString={string.activeHandlesCodes}
                onChange={this.onChangeModal}
              />
            )}
          </div>
        )
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow === index ? (
              string.deleteString[data]
            ) : (
              <SelectBox
                value={string.deleteString[data]}
                data={row}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (parseInt(value) !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            )}
            {/* {data} */}
          </div>
        )
      },
      {
        title: "설정",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {selectedRow !== index && (
              <Button
                onClick={() => {
                  this.setState({
                    selectedRow: index,
                    prevData: row,
                    editData: row,
                    isModify: true
                  });
                }}>
                수정하기
              </Button>
            )}
            {selectedRow === index && isModify && (
              <div>
                <Button
                  style={{ marginRight: "8px" }}
                  type="primary"
                  shape="circle"
                  icon="check"
                  onClick={() => {
                    this.handleFeeWalletUpdate();
                  }}
                />
                <Button
                  type="danger"
                  shape="circle"
                  icon="close"
                  onClick={() => {
                    this.setState({
                      editData: this.state.prevData,
                      selectedRow: "",
                      isModify: false
                    });
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">출금지갑</div>
        <WalletRegistryModal
          type="feeWallet"
          visible={this.state.createModalVisible}
          onCancel={() => {
            this.setState({ createModalVisible: false });
          }}
          onOk={this.onCreate}
        />
        <div className="wrapper mt-35 width-1240" />
        <span style={{ color: "blue" }}>
          * 출금지갑은 지갑에서 외부로 코인 출금 시 블록체인으로 보낼 코인을 담아놓는 지갑입니다.{" "}
          <br />
          지갑은 프로그램 코드와 연동되어 동작하기 때문에 임의로 수정하는 경우 지갑출금에 문제가
          발생할 수 있습니다.
        </span>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Button
            onClick={() => {
              this.setState({
                createModalVisible: true
              });
            }}>
            생성
          </Button>
          <div style={{ float: "right" }}>
            <label htmlFor="" style={{ marginRight: "10px" }}>
              검색조건&nbsp;&nbsp;&nbsp;
              <Checkbox style={{ marginRight: "10px" }} onChange={this.deletedTypeHandleChange}>
                삭제 계좌 포함
              </Checkbox>
              <SelectBox
                value={string.activeString[this.state.activeType]}
                code={string.activeCode}
                codeString={string.activeString}
                onChange={(value) => {
                  if (value !== this.state.activeType) this.activeTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
            </label>
            <Search
              placeholder="주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="feeWallet-table"
          rowKey={(record) => record.idx}
          columns={feeWalletColumns}
          dataSource={this.state.list}
          pagination={false}
        />
      </div>
    );
  }
}

export default FeeWallet;
