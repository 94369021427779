import React, { Component } from "react";
import { connect } from "react-redux";

import { getCoinDecimal } from "../../contexts/asyncContext";
import { Table, Button } from "antd";
import { numberFormat, formatDate, coinDivideFormat, checkCoinName } from "../../util";
import string from "../../string";
import { httpGet, httpPut, httpUrl } from "../../api/httpClient";
import WalletRegistryModal from "../../components/wallet/WalletRegistryModal";
import WalletDetail from "./../../components/wallet/WalletDetail";
import SelectBox from "./../../components/common/SelectBox";
import { showAlert } from "../../components/common/AlertModal";

class Foundation extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },

    createVisible: false,

    detailVisible: false,
    selectedData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getFoundationList({ pageNum: pager.current });
  };

  // 생성
  onCreate = () => {
    this.setState({
      createVisible: false
    });
    this.getFoundationList({});
    this.props.getCoinDecimal({ language: "ko" });
  };

  // 수정
  onUpdate = () => {
    const { pagination } = this.state;
    this.setState({
      detailVisible: false
    });
    this.getFoundationList({ pageNum: pagination.current });
  };

  // 삭제
  onDelete = (value, row) => {
    const { pagination } = this.state;

    if (
      global.confirm(
        `${checkCoinName(row.coinType)}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.foundationDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");

          this.getFoundationList({ pageNum: pagination.current });
          this.props.getCoinDecimal({ language: "ko" });
        })
        .catch((error) => {});
    }
  };

  // 재단 목록 조회
  getFoundationList = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.foundationList, [10, pageNum], {})
      .then((res) => {
        // console.log(`data = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.foundationList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getFoundationList();
  }

  render() {
    const { createVisible, detailVisible, selectedData } = this.state;

    const walletColums = [
      {
        width: 80,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data, row, index) => (
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              this.setState({
                detailVisible: true,
                selectedData: row
              });
            }}>
            {checkCoinName(data)}
          </div>
        )
      },
      {
        title: "재단 코인 잔액",
        dataIndex: "foundationBalance",
        className: "text-center",
        render: (data, row) => <div>{coinDivideFormat(data, row.coinType)}</div>
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <div>
            <SelectBox
              value={string.deleteString[data]}
              data={row}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (parseInt(value) !== data) this.onDelete(value, row);
              }}
            />
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">재단</div>
        <div className="wrapper mt-35 width-1240" />
        {createVisible && (
          <WalletRegistryModal
            type="foundation"
            visible={createVisible}
            onCancel={() => {
              this.setState({ createVisible: false });
            }}
            onOk={this.onCreate}
          />
        )}
        {detailVisible && (
          <WalletDetail
            modalData={selectedData}
            visible={detailVisible}
            onCancel={() => {
              this.setState({ detailVisible: false });
            }}
            onOk={this.onUpdate}
          />
        )}
        <div style={{ float: "left" }}>
          <Button
            onClick={() => {
              this.setState({ createVisible: true });
            }}
            style={{ marginBottom: "10px" }}>
            생성
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="foundation-table"
          rowKey={(record) => record.idx}
          columns={walletColums}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    getCoinDecimal: ({ language }) => dispatch(getCoinDecimal({ language }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(Foundation);
