import React, { Component } from "react";
import fileDownload from "js-file-download";

import { Table, Input, Button, Icon, Upload, Modal } from "antd";
import moment from "moment";
import {
  httpPut,
  httpUrl,
  httpGet,
  httpDownload,
  serverUrl
} from "../../api/httpClient";

import {
  numberFormat,
  amountFormat,
  coinMultiFormat,
  endDateFormat,
  formatDate,
  formatDateSecond,
  checkData,
  checkCoinName
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import LockCreateModal from "../../components/common/LockCreateModal";
import ConfirmInputModal from "../../components/common/ConfirmInputModal";
import { showAlert } from "../../components/common/AlertModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const Search = Input.Search;
class FoundationLock extends Component {
  state = {
    fCoinList: [],

    lockList: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchText: "",
    coinType: undefined,
    orderType: "IDX",
    orderByType: "ASC",
    selectedRowKeys: [],
    selectedRows: [],
    deleteFlag: 0,

    prevData: {},
    isModify: false,
    lockStatus: "ALL",

    selectData: {},
    modalType: "create",
    visible: false,
    coinList: {},

    isLoaded: false,
    deleteModalVisible: false
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getFoundationLock({ pageNum: pager.current });
    }
  };

  // 코인 종류 선택
  coinTypeHandleChange = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 정렬 순서 선택
  orderByTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderByType: value,
        isLoaded: true
      });
    }
  };

  // 정렬 조건 선택
  orderTypeHandleChange = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState({
        orderType: value,
        isLoaded: true
      });
    }
  };

  onSearch = (value) => {
    const { coinType } = this.state;
    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      this.setState(
        {
          searchText: value,
          isLoaded: true
        },
        () => {
          this.getFoundationLock({
            pageNum: 1
          });
        }
      );
    }
  };

  // lock 수정 시 주소검증...
  handleClickModify = (row, index) => {
    httpGet(httpUrl.getSearchSerial, [row.coinType, row.address], {})
      .then((res) => {
        this.setState({
          visible: true,
          modalType: "edit",
          selectData: {
            ...row,
            expireDate: row.expireDate,
            maxAmount: res.data
          },
          coinList: {
            coinType: this.state.fCoinList,
            serialNum: row.serial
          }
        });
      })
      .catch((e) => {});
  };

  // 값 변경
  handleChangeData = (value, type, coinType) => {
    let tempData = this.state.selectData;
    if (type === "amount") {
      tempData[type] = coinMultiFormat(value, coinType);
    } else tempData[type] = value;

    this.setState({
      selectData: tempData
    });
  };

  // 날짜 제한 설정
  disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().endOf("day" - 1);
  };

  // 삭제 시 삭제 메모 작성
  handleDeleteState = (value, row) => {
    let deleted = row.deleted;
    if (value !== deleted) {
      if (deleted) {
        this.onDelete({
          idx: row.idx,
          memoDeleted: row.memoDeleted,
          deleted: row.deleted
        });
      } else {
        this.setState({
          deleteModalVisible: true,
          selectedRow: row
        });
      }
    }
  };

  /* 삭제
     error - COIN_NOT_ENOUGH
  */
  onDelete = (params = {}) => {
    const { pagination } = this.state;
    if (
      global.confirm(
        `해당 LOCK을 ${params.deleted === 1 ? "복구" : "삭제"}하시겠습니까?`
      )
    ) {
      httpPut(httpUrl.lockDelete, [], {
        idx: params.idx,
        memoDeleted: params.memoDeleted
      })
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            params.deleted === 1 ? showAlert("restore") : showAlert("delete");

            this.getFoundationLock({
              pageNum: pagination.current
            });
            this.setState({
              deleteModalVisible: false
            });
          } else ShowErrorAlert(res.data.data);
        })
        .catch((e) => {});
    }
  };

  // 다중선택
  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows: selectedRows.map((row) => {
        return {
          ...row,
          memoDeleted: ""
        };
      })
    });
  };

  // 다중선택 삭제
  onMuptiDelete = (parmas = {}) => {
    const { selectedRows, pagination } = this.state;

    if (global.confirm(`선택하신 Lock들을 삭제 하시겠습니까?`)) {
      let idxs = [];
      selectedRows.forEach((row) => {
        idxs.push(row.idx);
      });
      // console.log(`idxs= ${idxs}`);
      let deleteDate = {
        idxs,
        memoDeleted: ""
      };
      httpPut(httpUrl.lockMuptiDelete, [], deleteDate)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showAlert("delete");
            this.setState({
              selectedRows: [],
              selectedRowKeys: []
            });

            this.getFoundationLock({
              pageNum: pagination.current
            });
          } else ShowErrorAlert(res.data.result);
        })
        .catch((e) => {});
    }
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    const {
      coinType,
      isLoaded,
      pagination,
      searchText,
      orderType,
      orderByType
    } = this.state;

    if (coinType === 0 || coinType === undefined) {
      alert("코인 종류를 선택해주세요.");
    } else {
      if (isLoaded) {
        alert("검색 후 가능합니다.");
      } else {
        let total = pagination.total <= 9 ? 10 : pagination.total;

        if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
          // console.log(`${excelFormat(startDate)} ~ ${excelFormat(endDate)}`);
          httpDownload(httpUrl.foundationLockExcel, [
            total,
            1,
            coinType,
            searchText,
            orderType,
            orderByType
          ])
            .then((res) => {
              // console.log(JSON.stringify(res, null, 4));
              fileDownload(res, `${checkCoinName(coinType)} LOCK 관리.xlsx`);
            })
            .catch((error) => {});
        }
      }
    }
  };

  // lock 목록 조회
  getFoundationLock = (params = {}) => {
    const { coinType, searchText, orderType, orderByType } = this.state;
    let pageNum = params.pageNum || 1;

    // console.log(`refresh!! ${JSON.stringify(params, null, 4)}`);
    if (params.refresh) {
      pageNum = this.state.pagination.current;
    }

    httpGet(
      httpUrl.foundationLockList,
      [10, pageNum, coinType, searchText, orderType, orderByType],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          lockList: res.data.lockList === null ? [] : res.data.lockList,
          pagination,
          deleteFlag:
            res.data.lockList === null
              ? 0
              : res.data.lockList.every((item) =>
                  item.deleted ||
                  endDateFormat(item.expireDate) <=
                    formatDateSecond(
                      moment()
                        .endOf("day" - 1)
                        .toString()
                    )
                    ? 1
                    : 0
                ),
          isLoaded: false
        });
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // 재단 코인 목록 조회
    httpGet(httpUrl.getFoundationList, [], {})
      .then((res) => {
        this.setState(
          {
            foundationList: res.data,
            fCoinList: res.data.map((item) => item.coinType),
            coinList: {
              coinType: res.data.map((item) => item.coinType)
            },
            coinType: res.data[0].coinType
          },
          () => {
            this.getFoundationLock();
          }
        );
      })
      .catch((e) => {});
  }

  render() {
    const {
      coinType,
      lockList,
      fCoinList,
      orderByType,
      orderType,
      selectedRowKeys,
      deleteFlag
    } = this.state;
    const { getFoundationLock } = this;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onRowSelectChange,
      getCheckboxProps: (record) => ({
        disabled:
          endDateFormat(record.expireDate) <=
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) || record.deleted === 1,
        key: record.key
      })
    };

    const uploadProps = {
      action: serverUrl + httpUrl.foundationLockCreateExcel,
      multiple: false,
      withCredentials: true,
      beforeUpload: (file) => {
        if (global.confirm("LOCK을 생성하시겠습니까?")) {
          return true;
        } else {
          return false;
        }
      },
      onSuccess: (file) => {
        var success = file.data.success;
        var fail = file.data.fail;
        var total = success + fail;

        var s =
          "총 : " + total + ", 성공 : " + success + ", 실패 : " + fail + "\n";
        Modal.info({
          title: "LOCK 결과",
          content: (
            <div>
              <p>
                {file.data.failAddresses.length > 0 ? (
                  <div>
                    <div>{`${s}`}</div>
                    <br />
                    {`- 실패한 LOCK\n`}
                    {file.data.failAddresses.length > 0 &&
                      file.data.failAddresses.map((value) => (
                        <div>{value}</div>
                      ))}
                  </div>
                ) : (
                  <div>{s}</div>
                )}
              </p>
            </div>
          ),
          okText: "확인",
          onOk() {
            getFoundationLock();
          }
        });
      },
      onError(err) {
        Modal.error({
          title: "LOCK 결과",
          content: "파일 업로드 실패",
          okText: "확인"
        });
      }
    };

    const approvalColumns = [
      {
        width: 120,
        key: "idx",
        title: "순번",
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "코인 종류",
        dataIndex: "coinType",
        key: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        width: 120,
        title: "등록일",
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        width: 120,
        title: "마감일",
        dataIndex: "expireDate",
        className: "text-center",
        render: (data, row, index) => <div>{formatDate(data)}</div>
      },
      {
        title: "아이디(이메일)",
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: "지갑주소",
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data, row, index) => (
          <span>
            {data === -1 ? "전체잠금" : amountFormat(data, row.coinType)}
          </span>
        )
      },
      {
        title: "메모",
        dataIndex: "memo",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "삭제 메모",
        dataIndex: "memoDeleted",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        width: 120,
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <div>
            {endDateFormat(row.expireDate) >
            formatDateSecond(
              moment()
                .endOf("day" - 1)
                .toString()
            ) ? (
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  this.handleDeleteState(value, row);
                }}
              />
            ) : (
              <span>{`만료`}</span>
            )}
          </div>
        )
      },
      {
        title: "수정",
        dataIndex: "",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {row.deleted === 0
              ? row.expireDate !== null && (
                  <Button
                    onClick={() => {
                      this.handleClickModify(row, index);
                    }}
                  >
                    수정
                  </Button>
                )
              : row.deleted === null
              ? "-"
              : "삭제"}
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {JSON.stringify(this.props.result, null, 4)} */}
        <div className="title">LOCK 관리</div>
        <div className="wrapper mt-35 width-1240" />
        {/* 생성 */}
        {this.state.visible && (
          <LockCreateModal
            pageType="lockManagement"
            visible={this.state.visible}
            modalType={this.state.modalType}
            editData={this.state.selectData}
            coinList={this.state.coinList}
            onCancel={() => {
              this.setState({
                visible: false,
                modalType: "create",
                selectData: {}
              });
            }}
            refresh={this.getFoundationLock}
          />
        )}
        {/* 삭제 */}
        {this.state.deleteModalVisible && (
          <ConfirmInputModal
            rootPage="lock"
            visible={this.state.deleteModalVisible}
            onOk={this.onDelete}
            onCancel={() => {
              this.setState({
                deleteModalVisible: false
              });
            }}
            selectedRow={this.state.selectedRow}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label style={{ marginRight: "20px" }}>
              코인{" "}
              <CoinSelectBox
                type="coinType"
                placeholder="선택해주세요."
                value={coinType}
                coinCode={fCoinList}
                onChange={(value) => {
                  if (value !== coinType) this.coinTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label>
              순서{" "}
              <SelectBox
                value={string.orderByString[orderByType]}
                code={string.orderByCode}
                codeString={string.orderByString}
                onChange={(value) => {
                  if (value !== orderByType)
                    this.orderByTypeHandleChange(value);
                }}
                style={{ width: "160px", marginRight: "10px" }}
              />
              <SelectBox
                value={string.foundationLockString[orderType]}
                code={string.foundationLockCode}
                codeString={string.foundationLockString}
                onChange={(value) => {
                  if (value !== orderType) this.orderTypeHandleChange(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
          </div>
          <div className="flex-row flex-center m-b-10">
            <label htmlFor="" style={{}}>
              검색기준&nbsp;
            </label>
            <Search
              placeholder="지갑주소를 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "400px" }}
            />
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div>{`total : ${numberFormat(this.state.pagination.total)}`}</div>
          <div className="flex-row">
            <Button
              className="m-r-10"
              onClick={() => {
                this.setState({
                  visible: true,
                  modalType: "create"
                });
              }}
            >
              LOCK 생성
            </Button>
            <Button
              style={{}}
              onClick={this.onMuptiDelete}
              disabled={deleteFlag ? true : false}
            >
              선택한 LOCK 삭제
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div style={{ color: "blue" }}>
            * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의
            해당 코인에 대해 모든 출금이 금지됩니다.
          </div>
          <div className="flex-row">
            <a href="/LOCKFormat.xlsx" download>
              <Button className="m-r-10">
                <Icon type="download" style={{ fontSize: "20px" }} />
                양식 다운로드
              </Button>
            </a>
            <Upload
              {...uploadProps}
              accept=".xlsx, .xls"
              showUploadList={false}
            >
              <Button className="m-r-10">
                <Icon type="upload" style={{ fontSize: "20px" }} />
                엑셀 업로드
              </Button>
            </Upload>
            <Button
              onClick={this.downloadExcel}
              disabled={lockList.length === 0 ? true : false}
            >
              <Icon type="download" style={{ fontSize: "20px" }} />
              LOCK 조회목록 다운로드
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="lock-table"
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return endDateFormat(record.expireDate) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              ) || record.deleted === 1
              ? "gray"
              : "";
          }}
          rowKey={(record) => record.idx}
          columns={approvalColumns}
          dataSource={lockList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
        />
      </div>
    );
  }
}

export default FoundationLock;
