import React, { Component } from "react";
import { connect } from "react-redux";

import { Table, Input, Button } from "antd";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import { getBlockChain, getCoinDecimal } from "../../contexts/asyncContext";

import string from "../../string";
import { numberFormat, coinDivideFormat, amountFormat } from "../../util";
import TokenDetail from "../../components/system/TokenDetail";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import { showAlert } from "../../components/common/AlertModal";

const Search = Input.Search;

class BlockChain extends Component {
  state = {
    searchText: "",
    detailModalVisible: false,
    detailData: {},

    rankModify: false,
    preRankList: [],
    rankList: [],
    duplRank: [],
    lastRank: 0,
    selectedRank: {},

    lastCoinType: 0
  };

  // global 변수에 language저장
  handleShowDetail = (params = {}) => {
    httpGet(httpUrl.blockChainDetail, [params.row.idx], {})
      .then((res) => {
        this.setState({
          modalType: "edit",
          detailModalVisible: true,
          detailData: res.data
        });
      })
      .catch((e) => {});
  };

  onOk = () => {
    this.props.getCoinDecimal({ language: "ko" });
    this.props.getBlockChain({
      searchText: this.state.searchText
    });
    window.location.reload();
  };

  onDelete = (value, row) => {
    const { searchText } = this.state;

    if (global.confirm(`${row.symbol}을(를) ${row.deleted ? "복구" : "삭제"}하시겠습니까?`)) {
      httpPut(httpUrl.blockChainDelete, [row.idx], {})
        .then((result) => {
          row.deleted ? showAlert("restore") : showAlert("delete");
          this.props.getBlockChain({ searchText: searchText });
        })
        .catch((error) => {});
    }
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.props.getBlockChain({
          searchText: value
        });
      }
    );
  };

  handleChangeRankInput = (value, rowIndex) => {
    this.setState({
      rankList: this.state.rankList.map((rank, index) => {
        if (index === rowIndex) {
          return {
            idx: rank.idx,
            rank: parseInt(value)
          };
        } else {
          return rank;
        }
      })
    });
  };

  handleCheckDuplRank = () => {
    let tempArr = [...this.state.rankList];
    let dupl = [];
    let sortArr = tempArr.sort((a, b) => a.rank - b.rank);
    //table data에서 수정한 rank 검색
    for (var i = 0; i < this.state.rankList.length - 1; i++) {
      if (sortArr[i + 1].rank === sortArr[i].rank) {
        if (i === 0 || sortArr[i - 1].rank !== sortArr[i].rank) {
          dupl.push(sortArr[i]);
        }
        dupl.push(sortArr[i + 1]);
      }
    }
    this.setState({
      duplRank: dupl
    });
    return dupl.length === 0 ? false : true;
  };

  handleUpdateRank = () => {
    // console.log(JSON.stringify(this.state.rankList, null, 4));
    if (global.confirm(`수정하시겠습니까?`)) {
      httpPut(httpUrl.blockChainRankUpdate, [], this.state.rankList).then((res) => {
        showAlert("update");
        this.props.getBlockChain({
          searchText: ""
        });
        this.setState({
          rankModify: !this.state.rankModify
        });
      });
    }
  };

  componentDidMount() {
    this.props.getBlockChain({
      searchText: ""
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (Array.isArray(props.result.data)) {
      if (props.result.data.length !== state.rankList.length) {
        let lastRank = 0;
        state.rankList = props.result.data.map((value) => {
          if (value.rank > lastRank) {
            lastRank = value.rank;
          }
          return {
            idx: value.idx,
            rank: value.rank
          };
        });
        state.lastRank = lastRank;
      }
    }
    return null;
  }

  render() {
    let lastRank = 0;
    let rankList = [];
    if (Array.isArray(this.props.result.data)) {
      this.props.result.data.forEach((value, index) => {
        rankList.push(value.rank);
        if (value.rank > lastRank) {
          lastRank = value.rank;
        }
      });
    }
    let coinList = reactLocalStorage.getObject("coinList");
    const {
      rankModify,
      duplRank,
      selectedRank,
      modalType,
      detailModalVisible,
      detailData,
      lastCoinType,
      preRankList
    } = this.state;

    const columns = [
      {
        title: "순번",
        dataIndex: "idx",
        key: "idx",
        width: 80,
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "랭크",
        dataIndex: "rank",
        key: "rank",
        width: 80,
        className: "text-center",
        render: (data, row, index) => (
          <div>
            {rankModify ? (
              <Input
                style={{
                  borderWidth: 1,
                  borderColor: this.state.rankList.map((item, index) => {
                    let bool = false;
                    for (let i = 0; i < duplRank.length; i++) {
                      if (item.idx === duplRank[i].idx) {
                        bool = true;
                      }
                    }
                    return bool;
                  })[index]
                    ? "red"
                    : "#d9d9d9"
                }}
                value={this.state.rankList.length !== 0 && this.state.rankList[index].rank}
                onChange={(e) => {
                  if (e.target.value > this.state.lastRank) {
                    alert(`최대 ${this.state.lastRank}을(를) 넘는 숫자를 입력 할 수 없습니다.`);
                  } else {
                    this.setState(
                      {
                        selectedRank: {
                          preRank: this.state.rankList[index].rank,
                          curRank: e.target.value
                        }
                      },
                      () => {
                        this.handleChangeRankInput(selectedRank.curRank, index);
                      }
                    );
                  }
                }}
              />
            ) : (
              <span>{data}</span>
            )}
          </div>
        )
      },
      {
        title: "심볼",
        dataIndex: "symbol",
        key: "symbol",
        // width: 80,
        className: "text-center",
        render: (data, row, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}>
            <img src={row.image} alt="symbolImage" style={{ width: "18px", marginRight: "8px" }} />
            <div>{data}</div>
          </div>
        )
      },
      {
        title: "코인 타입",
        dataIndex: "coinType",
        key: "coinType number",
        className: "text-center",
        width: 100
      },
      {
        title: "코인 이름",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => (
          <div>
            {coinList.map((list) => {
              if (data === list.coinType) return list.name;
              else return null;
            })}
          </div>
        )
      },
      {
        title: "카테고리",
        dataIndex: "category",
        key: "category",
        // width: 80,
        className: "text-center"
      },
      {
        title: "수수료",
        dataIndex: "fee",
        key: "fee",
        // width: 80,
        className: "text-center",
        render: (data, row) => {
          let fee = coinDivideFormat(data, row.coinType);
          if (row.symbol === "ETH") {
            fee = amountFormat(fee * 21000, row.coinType);
          } else if (coinList.find((coin) => coin.coinType === row.coinType).category === "ERC20") {
            fee = amountFormat(fee * 50000, row.coinType);
            if (row.coinType === 104) {
            }
          } else {
            fee = amountFormat(fee);
          }
          return <div>{`${fee}`}</div>;
        }
      },
      {
        title: "상태",
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row, index) => (
          <div>
            <SelectBox
              value={string.deleteString[data]}
              code={string.toggleCode}
              codeString={string.deleteString}
              onChange={(value) => {
                if (value !== row.deleted) {
                  this.onDelete(value, row);
                }
              }}
            />
          </div>
        )
      },
      {
        title: "",
        className: "text-center",
        render: (data, row, index) => (
          <Button
            disabled={rankModify}
            onClick={() =>
              this.handleShowDetail({
                row: row
              })
            }>
            정보수정
          </Button>
        )
      }
    ];

    return (
      <div>
        <div className="title">BlockChain 관리</div>
        <div className="wallet-log-wrapper">
          <TokenDetail
            modalType={modalType}
            visible={detailModalVisible}
            modalData={detailData}
            onOk={this.onOk}
            lastRank={lastRank + 1}
            rankList={rankList}
            onCancel={() => {
              this.setState({
                detailModalVisible: false,
                modalType: "",
                detailData: {}
              });
            }}
            lastCoinType={lastCoinType}
            refresh={this.handleShowDetail}
          />
          <div style={{ float: "left" }}>
            <Button
              onClick={() => {
                let arr = this.props.result.data.sort((x, y) => x.coinType - y.coinType);
                this.setState({
                  modalType: "create",
                  detailModalVisible: true,
                  lastCoinType: arr[this.props.result.data.length - 1].coinType + 1
                });
              }}
              style={{ marginRight: "8px" }}>
              생성
            </Button>
            <Button
              onClick={() => {
                //수정완료버튼 클릭시
                if (rankModify) {
                  //중복 없음
                  if (!this.handleCheckDuplRank()) {
                    this.handleUpdateRank();
                  } else {
                    alert(`중복된 숫자가 있습니다.`);
                  }
                } else {
                  //랭크수정 버튼 클릭
                  this.setState({
                    rankModify: !rankModify,
                    preRankList: this.state.rankList
                  });
                }
              }}
              style={{ marginRight: "8px" }}>
              {rankModify ? "수정완료" : "랭크수정"}
            </Button>
            {rankModify && (
              <Button
                onClick={() => {
                  this.setState({
                    rankModify: !rankModify,
                    selectedRank: {},
                    rankList: preRankList,
                    duplRank: []
                  });
                }}>
                취소
              </Button>
            )}
          </div>
          <div className="flex-row flex-center m-b-10" style={{ float: "right" }}>
            <label>검색기준&nbsp;</label>
            <Search
              placeholder="카테고리, 심볼을 입력하세요"
              enterButton="검색"
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
          <div style={{ clear: "both" }} />
          <Table
            id="wallet-log-table"
            columns={columns}
            rowKey={(record) => record.idx}
            dataSource={this.props.result.data}
            style={{ marginBottom: "10px" }}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    result: state.async.fetchData
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    getBlockChain: ({ searchText }) => dispatch(getBlockChain({ searchText })),
    getCoinDecimal: ({ language }) => dispatch(getCoinDecimal({ language }))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(BlockChain);
